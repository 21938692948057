import { getPersonData } from "../api"
import { toastError, toastWarn } from "../../global/errors.toast";
import {
    GET_PERSON_REQUEST,
    GET_PERSON_SUCCESS,
    GET_PERSON_FAILURE,
} from "../const";


const request = () => ({ type: GET_PERSON_REQUEST });

const success = (person) => ({
    type: GET_PERSON_SUCCESS,
    payload: { person },
});
const failure = () => ({ type: GET_PERSON_FAILURE });

export const receivePerson = (params) => async (dispatch) => {
    dispatch(request());
    try {
        getPersonData(params).then(({ data: receivePersonData }) => {
            dispatch(success(receivePersonData));
        }).catch((error) => {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                }
            }
        })
    } catch (e) {
        dispatch(failure());
    };
};

