import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receivePersonalList } from "./actions/PersonalActions"
import { ROUTES } from "../../config"
import { Link } from "react-router-dom";
import "./index.scss"
import SearchItem from "../global/components/search/search";

/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const PersonalList = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(receivePersonalList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const personListData = useSelector(state => state.personList.personal);
    const renderPersonList = personListData.map((item) => {
        return (<div key={Math.random()} className="col-xl-4 col-md-6 col-sm-12">
            <div className="card card-cascade">
                <Link to={`${ROUTES.person}?id=${item.code}`}>
                    <div className="view view-cascade overlay">
                        <img className="card-img-top" src={item.image} alt={item.name} />
                        <div className="mask rgba-white-slight"></div>
                    </div>
                </Link>
                <div className="card-body card-body-cascade text-center">
                    <h4 className="card-title"><strong>{item.name}</strong></h4>
                    <h6 className="card-body-job">{item.tags}</h6>
                </div>
            </div>
        </div>

        )
    })
    return (<>
        <div className="leader-list">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-sm-12">
                        <div className="container">
                            <div className="card-list">
                                <div className="row">
                                    {renderPersonList}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-12 widget-column">
                        <SearchItem />
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default PersonalList;




