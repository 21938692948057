import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receiveStation } from "./actions/stationActions"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWind, faCloudRain, faDroplet, faPrescriptionBottle } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import "./cabinet.scss";




/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */

const Cabinet = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(receiveStation());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const staionData = useSelector(state => state.station.data);
    const directions = ["Пн", "Пн-Сх", "Сх", "Пд-Сх", "Пд", "Пд-Зх", "Зх", "Пн-зх"]
    let dateNow = new Date().toLocaleString();

    const dateConvertor = (date) => {
        let data = new Date(date).toLocaleTimeString();
        return data
    }

    const barometerState = (state) => {
        if (state === "Steady") {
            return "Стійкий"
        }
        if (state === "Falling Slowly") {
            return "Повільно падаючий"
        }
        else {
            return null
        }
    }

    const getDirection = (angle) => {
        let index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
        return directions[index]
    }

    return (<div>
        <div className="cabinet-container">
            <div className="container">
                <div className="row">
                    <div className="col-my col-xl-8 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-my col-xl-6 col-sm-12 col-xs-12">
                                <h2>Особистий кабінет</h2>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item"><a href={'http://oc.vd.lutsk.ua/'} className="link" target="_blank" rel="noreferrer">Вхід КП "Луцькводоканал"</a></li>
                                    <li className="list-group-item"><a href={'https://oc.teplo-dkp.lutsk.ua/ua/'} className="link" target="_blank" rel="noreferrer">Вхід ДКП "Луцьктепло"</a></li>
                                    <li className="list-group-item"><a href={'https://ok.lskap.com.ua/home'} className="link" target="_blank" rel="noreferrer">Вхід ЛСКАП "Луцькспецкомунтранс"</a></li>
                                    <li className="list-group-item"><a href={'https://oc.vd.lutsk.ua/registration/personal_data'} className="link" target="_blank" rel="noreferrer">Реєстрація</a></li>
                                    <li className="list-group-item"><Link to={'/blog/post?id=628'} className="link">Допомога</Link></li>
                                    <li className="list-group-item"><Link to={'/tables?id=639'} className="link">Коди вулиць для реєстрації</Link></li>
                                </ul>
                            </div>
                            <div className="col-my col-xl-6 col-sm-12 col-xs-12">
                                <h2>ОПЛАТА ЗА ПОСЛУГИ</h2>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item"><Link to={'/blog/post?id=852'} className="link">Загальна інформація</Link></li>
                                    <li className="list-group-item"><Link to={'/blog/post?id=854'}className="link">Громадська приймальня</Link></li>
                                    <li className="list-group-item"><a href={'https://next.privat24.ua/payments/form/%7B%22companyID%22:%221242742%22,%22form%22:%7B%22query%22:%22%D0%BB%D1%83%D1%86%D1%8C%D0%BA%22%7D%7D'} className="link" target="_blank" rel="noreferrer">Оплата в Приват 24</a></li>
                                    <li className="list-group-item"><a href={'https://www.komunal.lutsk.ua/KomVC.php'} className="link" target="_blank" rel="noreferrer">Місцевий Обчислювальний Центр</a></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-my col-xl-4 col-sm-12 col-xs-12">
                        <div className="card" >
                            <div className="card-header">
                                Луцькводоканал
                                <p>Показники станом на {dateNow}</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col text-center">
                                        <div className="dergee">{staionData.temperature}°C</div>
                                    </div>
                                    <div className="col">
                                        <div className="high-low-data">
                                            <p>Макс:  <b>{staionData.hiTemp}°C</b> <span>o {dateConvertor(staionData.hiTempDate)}</span></p>
                                            <p>Мін:  <b>{staionData.loTemp}°C</b> <span>o {dateConvertor(staionData.loTempDate)}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row weather-data">
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faWind} />
                                    </div>
                                    <div className="col-10">
                                        <h3>Вітер: <span>{staionData.wind} м/c {getDirection(staionData.windDirection)}</span></h3>
                                        <p>Найшвидший порив: {staionData.gust} м/c</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row weather-data">
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faDroplet} />
                                    </div>
                                    <div className="col-10">
                                        <h3>Вологість: <span>{staionData.humidity}%</span></h3>
                                        <p>Відчувається як {staionData.temperatureFeelLike}°C</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row weather-data">
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faCloudRain} />
                                    </div>
                                    <div className="col-10">
                                        <h3>Опади: <span>{staionData.rain} мм.</span></h3>
                                        <p> Сумарно за сезон: {staionData.seasonalRain} мм.</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row weather-data">
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faPrescriptionBottle} />
                                    </div>
                                    <div className="col-10">
                                        <h3>Тиск: <span>{staionData.barometer} мм. р.т.</span></h3>
                                        <p>{barometerState(staionData.barometerTrend)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >)

}

export default Cabinet;