import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import slide_1 from "../../assets/images/gallery/1.jpg";
import logo from "../../assets/images/logo.png"
import "./slider.scss";


/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const Slider = () => {

    return (<div>
        <div className="slider-container">
            <div className="container-fluid">
                <Swiper
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide><img src={slide_1} alt="" />
                        <div className="block">
                            <div className="info">
                                <div className="row">
                                    <div className="col-4">
                                        <img src={logo} alt="Logo" />
                                    </div>
                                    <div className="col-8">
                                        <div className="text">
                                            КП «ЛУЦЬКВОДОКАНАЛ»
                                            <p>90 РОКІВ ПРАЦЮЄМО НА БЛАГО МІСТА</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="more-info">
                                Постачаємо головне – чисту, якісну і безпечну воду з артезіанських свердловин. Разом із усією Україною працюємо заради перемоги, миру і добробуту рідної держави.
                                </div>
                                <div className="bot-links d-flex flex-column">
                                    <a href="https://t.me/lutskvodokanal_bot" target="_blank" rel="noreferrer noopener"><button type="button" className="btn">Телеграм бот</button></a>
                                    <a href="viber://pa?chatURI=lutskvodokanalbot" target="_blank" rel="noreferrer noopener"><button type="button" className="btn viber">Viber бот</button></a>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>
        </div>
    </div>)

}

export default Slider;