import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import "./index.scss"
const ToTop = () => {

  return (<>
  <div className="back-to-top">
  <ScrollToTop smooth color="#6f00ff" className="back-to-top" component={<FontAwesomeIcon icon={faArrowUp} />}/>
  </div>
  </>

  );
}
export default ToTop;