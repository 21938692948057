import React from "react";
import { FacebookShareButton,TelegramShareButton, TwitterShareButton, ViberShareButton, WhatsappShareButton } from "react-share";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTelegram, faWhatsapp, faTwitter, faViber } from '@fortawesome/free-brands-svg-icons'
import "./index.scss"
const SocialMediaButtons = ({ url, classname }) => {

    return (<>
        <div className={classname}>
          
            <div className="buttons float-right">
                <FacebookShareButton
                    url={url}
                    className="social-icon"
                >
                    <FontAwesomeIcon icon={faFacebook} />
                </FacebookShareButton>
                <TelegramShareButton
                    url={url}
                    className="social-icon"
                >
                    <FontAwesomeIcon icon={faTelegram} />
                </TelegramShareButton>
                <ViberShareButton
                    url={url}
                    className="social-icon"
                >
                    <FontAwesomeIcon icon={faViber} />
                </ViberShareButton>
                <TwitterShareButton
                    url={url}
                    className="social-icon"
                >
                    <FontAwesomeIcon icon={faTwitter} />
                </TwitterShareButton>
                <WhatsappShareButton
                    url={url}
                    className="social-icon"
                >
                    <FontAwesomeIcon icon={faWhatsapp} />
                </WhatsappShareButton>
            </div>
        </div>
    </>);
}

export default SocialMediaButtons;