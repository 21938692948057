require('dayjs/locale/uk');
const updateLocale = require('dayjs/plugin/updateLocale');
const calendar = require('dayjs/plugin/calendar');
const dayjs = require('dayjs');

dayjs.locale('uk');
dayjs.extend(updateLocale);
dayjs.extend(calendar);

dayjs.updateLocale('uk', {
    weekdaysShort: [
      "Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"
    ]
  })

/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */
const formater = (date) => {
    const tzOffset = (new Date()).getTimezoneOffset()
    const dt = dayjs(date)

    return date ?  dt.calendar(null, {
        sameDay: '[Сьогодні о] HH:mm', 
        nextDay: '[Завтра о] HH:mm',
        nextWeek: 'dddd [о] HH:mm',
        lastDay: '[Вчора о] HH:mm',
        lastWeek: 'ddd, D MMMM, YYYY',
        sameElse: 'ddd, D MMMM, YYYY'
      }) : null
}


const DateFormat = (props) => {
    if (props.type === 'news'){
        return formater(props.date)
    }
    if (props.type === undefined){
        return formater(props.date)
    }
    if (props.type === 'page'){
        return ""
    }


}

export default DateFormat;