import {
    GET_TABLE_REQUEST,
    GET_TABLE_SUCCESS,
    GET_TABLE_FAILURE,
} from "../const";


const initialState = {
    columns: [],
    rows: [],
    title: null,
    load: false
}

const handlers = {
    [GET_TABLE_REQUEST]: (state) => ({ ...state, load: false}),
    [GET_TABLE_SUCCESS]: (state, { payload: { tables } }) => ({
        ...state,
        load: true,
        ...tables
    }),
    [GET_TABLE_FAILURE]: (state) => ({ ...state, load: false }),
    DEFAULT: (state) => state,
};

const tablesReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default tablesReducer;