import {
    GET_ALERTS_REQUEST,
    GET_ALERTS_SUCCESS,
    GET_ALERTS_FAILURE,
} from "../const";


const initialState = {
    active: [],
    inactive: []
};

const handlers = {
    [GET_ALERTS_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_ALERTS_SUCCESS]: (state, { payload: { alerts } }) => ({
        ...state,
        loading: false,
        ...alerts,
    }),
    [GET_ALERTS_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};

const alertsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default alertsReducer;