/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import SocialMediaButtons from "../global/shareSocial"
import SearchItem from "../global/components/search/search";
import SeoComponent from "../global/shareMeta"
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import "./index.scss";



/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */
const data = [
    {
        name: '2010',
        uv: 23510,
        pv: 187 * 120,
    },
    {
        name: '2011',
        uv: 23319,
        pv: 131 * 120,
    },
    {
        name: '2012',
        uv: 23125,
        pv: 99 * 120,
    },
    {
        name: '2013',
        uv: 22325,
        pv: 87 * 120,
    },
    {
        name: '2014',
        uv: 20764,
        pv: 66 * 120,
    },
    {
        name: '2015',
        uv: 19673,
        pv: 46.7 * 120,
    },
    {
        name: '2016',
        uv: 19640,
        pv: 46 * 120,
    },
];

const EconomyPost = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const url = window.location.href;

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <div>
        <SeoComponent />
        <div className="economy-post">
            <div className="container">

                <div className="row">
                    <div className="col-xl-8 col-sm-12">

                        <div className="card mt-4">
                            <SocialMediaButtons url={url} classname="share-buttons-page" />
                            <div className="row">
                                <div className="col-xl-12 col-sm-12">
                                    <div className="img-square-wrapper">
                                    </div>
                                </div>
                                <div className="col-xl-12 col-sm-12">
                                    <div className="card-body h-100">

                                        <div className="d-flex justify-content-between">

                                        </div>
                                        <h4 className="card-title">Економічні показники</h4>

                                        <p className="card-text" />
                                        <p>&nbsp; &nbsp; &nbsp;Одним із стратегічно важливих підприємств, розташованих на території м. Луцька, яке відноситься до суб’єктів природної монополії, є комунальне підприємство «Луцькводоканал».</p>
                                        <p>&nbsp; &nbsp; &nbsp;КП «Луцькводоканал» забезпечує населення м. Луцька та приміських сіл, промислові та інші підприємства і організації високоякісними послугами з подачі холодної питної води та приймання стічних вод до комунальної каналізації (станом на 01.01.17р. у підприємства наявні&nbsp;<strong><em><u>94810 абонентів-фізичних осіб</u></em></strong>&nbsp;та&nbsp;<strong><em><u>2 112 юридичні особи</u></em></strong>) згідно укладених договорів та діючих тарифів, і відноситься до об'єктів питного водопостачання та водовідведення.</p>
                                        <p>&nbsp; &nbsp; &nbsp;Водопостачання м. Луцька здійснюється з 52-х артезіанських свердловин, глибина яких коливається від 80 до 170 м. Підйом води у 2016 році становить&nbsp;<strong>16499,8 тис. м<sup>3</sup>&nbsp;або 45,1 тис. м<sup>3</sup>/добу.</strong></p>
                                        <p>&nbsp; &nbsp; &nbsp;Водопостачання здійснюється із підземних джерел п’яти водозаборів (Дубнівського, Ново-Дубнівського, Східного, Південно-Східного та Омелянівського) і річкового водозабору, який на даний час знаходиться на реконструкції. Всі площадки водопідготовки мають комплекс споруд по очищенню та обробці сирої води з парком резервуарів чистої води в кількості 12 шт., загальним об’ємом 51,3 тис. м<sup>3</sup>. На сьогодні, знезараження питної води відбувається за допомогою гіпохлоридних установок, що є економніше та безпечніше.</p>
                                        <div className="chart-post-data">
                                            <ResponsiveContainer width="100%" height="100%" >
                                                <ComposedChart
                                                    width={500}
                                                    height={400}
                                                    data={data}
                                                    scale={'4'}
                                                    margin={{
                                                        top: 50,
                                                        right: 20,
                                                        bottom: 40,
                                                        left: 20,
                                                    }}
                                                >
                                                    <CartesianGrid stroke="#f5f5f5" />
                                                    <XAxis dataKey="name" scale="band" />
                                                    <Tooltip
                                                        formatter={(value, name) => {
                                                            if (name === "Витрати газу (тис. м3)") {
                                                                return value / 120
                                                            }
                                                            else {
                                                                return value
                                                            }
                                                        }}
                                                    />
                                                    <Legend radius={[10, 0, 0, 10]}/>
                                                    <Bar dataKey="uv" barSize={20} fill="#413ea0" name="Енергоспоживання (тис. кв)" />
                                                    <Line type="monotone" dataKey="pv" stroke="#ff7300" name="Витрати газу (тис. м3)" />
                                                </ComposedChart>
                                            </ResponsiveContainer>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-12 widget-column mt-0">
                        <SearchItem />
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default EconomyPost;

