import {
    GET_HOMEPOST_REQUEST,
    GET_HOMEPOST_SUCCESS,
    GET_HOMEPOST_FAILURE,
} from "../const";


const initialState = {
    posts: []
};

const handlers = {
    [GET_HOMEPOST_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_HOMEPOST_SUCCESS]: (state, { payload: { homePost } }) => ({
        ...state,
        loading: false,
        ...homePost,
    }),
    [GET_HOMEPOST_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};


const homePostReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default homePostReducer;