import {
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILURE,
} from "../const";


const initialState = {
    categories: [],
    posts:[]
};


const handlers = {
    [GET_CATEGORY_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_CATEGORY_SUCCESS]: (state, { payload: { categories } }) => ({
        ...state,
        loading: false,
        ...categories,
    }),
    [GET_CATEGORY_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};


const categoriesReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default categoriesReducer;