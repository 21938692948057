import { getCategorieshData  } from "../api"
import { toastError, toastWarn } from "../../../../global/errors.toast";
import {
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILURE,
} from "../const";


const request = () => ({ type: GET_CATEGORY_REQUEST});

const success = (categories) => ({
    type: GET_CATEGORY_SUCCESS,
    payload: { categories },
});
const failure = () => ({ type: GET_CATEGORY_FAILURE});

export const receiveCategoriesData = () => async (dispatch) => {
    dispatch(request());
    try {
        const { data: receiveCategorieshData} = await getCategorieshData().catch((error)=> {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                }
            }})
        dispatch(success(receiveCategorieshData));
    } catch (e) {
        dispatch(failure());
    };
};