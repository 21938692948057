import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import DateFormat from "../../../../components/dateFormat";
import "./index.scss";
/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */

const PurchasesItem = (props) => {
  return (
    <>
      <div className="col-xl-6 col-sm-12">
        <div className="card-blog">
          <div className="card mt-4">
            <div className="card-body  h-100 p-4">
              <h4 className="card-title">{props.name}</h4>
              <p
                className="card-text mb-4"
                dangerouslySetInnerHTML={{ __html: (props.body.length < 470 ? props.body : props.body.substring(350, 0) + "...").replace(/<img[^>]*>/g, "") }}
              />
              <div className="card-footer align-bottom">
                <div className="row">
                  <div className="col d-flex flex-row bd-highlight pl-4">
                    <DateFormat date={props.date} />
                  </div>
                  <div className="col d-flex flex-row-reverse bd-highlight">
                    <Link to={ROUTES.purchase + "?id=" + props.code}>
                      Читати далі <FontAwesomeIcon icon={faArrowRight} />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchasesItem;
