/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receivePostCategoryList } from "./actions/postCategoryListActions"
import BlogItem from "../../modules/global/components/blogItem/blogItem";
import "./index.scss";
import SearchItem from "../../modules/global/components/search/search";

/**

import "./index.scss";

/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const PostsCategoryList = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const category_id = urlParams.get('category');
    const dispatch = useDispatch();
    const [page, setPage] = useState(2);
    const { posts, total } = useSelector(state => state.postCategoryList);
    

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(receivePostCategoryList( { page: 1, category: category_id }));
        setPage(2)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category_id]);

    const getNextPage = () => {
        dispatch(receivePostCategoryList({ page: page, category: category_id }));
        setPage(page + 1);
    };

    const categoryListRender = posts.map(item => {
        return <BlogItem key={Math.random()} image={item.image}
            name={item.name}
            body={item.body}
            code={item.code}
            date={item.date} />
    })
    return (<div>
        <div className="blog-main">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-sm-12">
                        {categoryListRender}

                    </div>
                    <div className="col-xl-4 col-sm-12">
                        <SearchItem/>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center btn-blog">
                            {posts.length < total && <button type="button" className="btn" onClick={getNextPage}>Показати ще</button>}
                        </div>

        </div>

    </div>)

}

export default PostsCategoryList;

