import {
    GET_FOOTERNAV_REQUEST,
    GET_FOOTERNAV_SUCCESS,
    GET_FOOTERNAV_FAILURE,
} from "../const";


const initialState = {
    menu: []
};

const handlers = {
    [GET_FOOTERNAV_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_FOOTERNAV_SUCCESS]: (state, { payload: { menu } }) => ({
        ...state,
        loading: false,
        ...menu,
    }),
    [GET_FOOTERNAV_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};

const footernavReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default footernavReducer;