import {
    GET_SchedulePage_REQUEST,
    GET_SchedulePage_SUCCESS,
    GET_SchedulePage_FAILURE,
} from "../const";


const initialState = {
    tables: [],
    load: false
}


const handlers = {
    [GET_SchedulePage_REQUEST]: (state) => ({ ...state, load: false}),
    [GET_SchedulePage_SUCCESS]: (state, { payload: { SchedulePage } }) => ({
        ...state,
        load: true,
        ...SchedulePage
    }),
    [GET_SchedulePage_FAILURE]: (state) => ({ ...state, load: false }),
    DEFAULT: (state) => state,
};

const SchedulePageReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default SchedulePageReducer;