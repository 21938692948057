import { getNavData } from "../api"
import { toastError, toastWarn } from "../../../../global/errors.toast";
import {
    GET_NAV_REQUEST,
    GET_NAV_SUCCESS,
    GET_NAV_FAILURE,
} from "../const";


const request = () => ({ type: GET_NAV_REQUEST});

const success = (menu) => ({
    type: GET_NAV_SUCCESS,
    payload: { menu },
});
const failure = () => ({ type: GET_NAV_FAILURE});

export const receiveNav = (params) => async (dispatch) => {
    dispatch(request());
    try {
        const { data: receiveNavData } = await getNavData(params).catch((error)=> {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                }
            }})

        dispatch(success(receiveNavData));
    } catch (e) {
        dispatch(failure());
    };
};