import {
    GET_PERSON_REQUEST,
    GET_PERSON_SUCCESS,
    GET_PERSON_FAILURE,
} from "../const";


const initialState = [
    {
        code: null,
        name: null,
        body: null,
        catID: null,
        tags: null,
        image: null
    },
    {
        load: false
    }   
]

const handlers = {
    [GET_PERSON_REQUEST]: () => ({ ...initialState, load: false }),
    [GET_PERSON_SUCCESS]: (state, { payload: { person } }) => ({
        ...state,
        load: true,
        ...person
    }),
    [GET_PERSON_FAILURE]: (state) => ({ ...state, load: false }),
    DEFAULT: (state) => state,
};

const singlePersonReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default singlePersonReducer;