import {
    GET_SEARCH_REQUEST,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_FAILURE,
} from "../const";


const initialState = {
    posts: []
};
const getSearch = ({ state, posts, page}) => {
    return page !== 1 ? [...state.posts, ...posts.posts] : [...posts.posts];
}
const handlers = {
    [GET_SEARCH_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_SEARCH_SUCCESS]: (state, { payload: { posts, page, searchData} }) => ({
        ...state,
        loading: false,
        total: posts.total,
        posts: getSearch({state, posts, page, searchData}),
    }),
    [GET_SEARCH_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};


const searchReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default searchReducer;