import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receiveCategoriesData } from "./actions/CategoryActions"
import { ROUTES } from "../../../../config"
import { Link } from "react-router-dom";
import "./index.scss"
const dayjs = require('dayjs')
/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const SearchItem = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(receiveCategoriesData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const categoriesList = useSelector(state => state.categories);

    const renderCategories = categoriesList.categories.map(category => {
        return (
            <li key={Math.random()} className="list-group-item d-flex justify-content-between align-items-center">
                <Link to={`${ROUTES.postCategories}?category=${category.term_id}`}> {category.name}</Link>
                <span className="badge badge-primary badge-pill">{category.count}</span></li>
        )
    })

    const renderResentPosts = categoriesList.posts.map(item => {
        return (
            <div key={Math.random()} className="single-post">
              

                    
                    
                    <h6><Link to={`${ROUTES.post}?id=${item.code}`}>{item.name.length < 40 ? item.name : item.name.slice(0, 40) + '...'} </Link></h6>
                    <hr className="w-100" />
                    <span className="font-small grey-text">{dayjs(item.date).format('DD/MM/YYYY')}</span>
                    
                </div>
  
        )
    })

    return (<>
        <div className="search-item">
            <section className="section mb-5">
                <h3 className="font-weight-bold mt-2"><strong>Категорії</strong></h3>
                <hr className="blue title-hr" />
                <ul className="list-group mt-4">
                    {renderCategories}
                </ul>
            </section>
            <section className="section widget-content">
                <h3 className="font-weight-bold pt-2"><strong>Інші новини</strong></h3>
                <hr className="blue title-hr mb-4" />
                <div className="card card-body pb-0">
                    {renderResentPosts}
                </div>
            </section>

        </div>
    </>)
}

export default SearchItem;




