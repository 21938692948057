/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import "./index.scss";
import CollapceComponent from "../../components/collapse/collapse"
import ContactsMapComponent from "../../components/contacts_map/contacts_map";

/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  return (<div>
    <ContactsMapComponent/>
    <CollapceComponent/>

  </div>)

}

export default ContactPage;


