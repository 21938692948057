/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receiveSinglePost } from "./actions/singlePostActions"
import LazyLoad from 'react-lazyload';
import { Link } from "react-router-dom";
import { ROUTES } from "../../config";
import SocialMediaButtons from "../../modules/global/shareSocial"
import SearchItem from "../../modules/global/components/search/search";
import NotFound from "../../modules/notFound404"
import DateFormat from "../dateFormat";
import SeoComponent from "../../modules/global/shareMeta"
import ReactToPrint from 'react-to-print';
import "./index.scss";



/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const BlogPost = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const postId = urlParams.get('id') === null ? -1 : urlParams.get('id');
    const dispatch = useDispatch();
    const url = window.location.href;
    const componentRef = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(receiveSinglePost({ id: postId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postId]);
    const singlePostData = useSelector(state => state.singlePost);
    return !singlePostData[0].code && singlePostData?.load ? <NotFound /> : (<div>
        <SeoComponent postTitleData={singlePostData[0]?.name} postDescriptionData={singlePostData[0]?.body} postCoverData={singlePostData[0]?.image} />
        <div className="blog-post" >
            <div className="container">

                <div className="row">
                    <div className="col-xl-8 col-sm-12" >

                        {singlePostData?.load ? <div className="card mt-4" ref={componentRef}>
                            {singlePostData[0]?.post_type === 'page' ? <SocialMediaButtons url={url} classname="share-buttons-page" /> : null}
                            <div className="row">
                                <div className="col-xl-12 col-sm-12">
                                    <div className="img-square-wrapper">
                                        {singlePostData[0]?.image ? <LazyLoad><img className="w-100" src={singlePostData[0]?.image} alt={singlePostData[0]?.name} /></LazyLoad> : null}
                                    </div>
                                </div>
                                <div className="col-xl-12 col-sm-12">
                                    <div className="card-body  h-100">
                                        <h4 className="card-title"><b>{singlePostData[0].name}</b></h4>
                                        {singlePostData[0].post_type === "news" ? singlePostData[0].category.map(category => {
                                            return <span key={Math.random()}><Link to={`${ROUTES.postCategories}?category=${category.term_id}`} className="link category-link">{category.name}</Link>&nbsp;&nbsp;</span>
                                        }) : ''}
                                        {singlePostData[0]?.image ? <hr /> : null}


                                        {singlePostData[0]?.post_type === 'news' ? <div className="d-flex justify-content-between">
                                            <div className="post-date"><DateFormat date={singlePostData[0].date} type={singlePostData[0]?.post_type} /></div>
                                            <SocialMediaButtons url={url} />
                                        </div> : null}




                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: singlePostData[0].body }} />
                                    </div>
                                </div>
                            </div>
                            <br />

                        </div> : null}
                        <div className="btn-blog flex-row-reverse d-none">
                        <ReactToPrint
                            content={() => componentRef.current}
                            trigger={() => <button className="btn">Зберегти</button>}
                        />
                        </div>

                    </div>

                    <div className="col-xl-4 col-md-12 widget-column mt-0">
                        <SearchItem />
                    </div>
                </div>
            </div>
        </div>
    </div>)

}

export default BlogPost;

