
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "./config";
import React, { useState, useEffect } from 'react'
import HomePage from "./modules/home";
import ContactPage from "./modules/contacts";
import BlogPage from "./modules/blog"
import PurchasesPage from "./modules/purchases";
import PurchasesPost from "./components/purchasesPost/purchasesPost";
import BlogPost from "./components/blogPost/blogPost";
import NotFound from "./modules/notFound404";
import PostsCategoryList from "./components/categories/categories"
import PersonalList from "./modules/personal"
import Person from "./modules/personal/person"
import ErrorOccured from "./modules/errorOcurred";
import Tables from "./modules/tables"
import SchedulePageModule from "./modules/SchedulePage"
import PopulationModule from "./modules/population"
import ChartPost from "./modules/charts";
import EconomyPost from "./modules/economy";
import runOneSignal from './oneSignal';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import 'swiper/css';
import "swiper/css/bundle";
import "swiper/css/pagination";
import "react-placeholder/lib/reactPlaceholder.css";
import 'react-toastify/dist/ReactToastify.css';
import "./index.scss";
import PolicyPost from "./modules/greenka/policy";



const { root, contact, blog, post, postCategories, error, personList, person, table, schedule, population, energy, economy, purchases, purchase, greenkaPolicy } = ROUTES;

/**
 * @desc component "App".
 * @return {JSX.Element} - main component with routing
 */

const App = () => {
    useEffect(() => {
      runOneSignal();
    })
  return (
    <Switch>
      <Route exact path={root} component={HomePage} />
      <Route exact path={contact} component={ContactPage} />
      <Route exact path={blog} component={BlogPage} />
      <Route exact path={post} component={BlogPost} />
      <Route exact path={purchase} component={PurchasesPost} />
      <Route exact path={purchases} component={PurchasesPage} />
      <Route exact path={postCategories} component={PostsCategoryList} />
      <Route exact path={personList} component={PersonalList} />
      <Route exact path={person} component={Person} />
      <Route exact path={error} component={ErrorOccured} />
      <Route exact path={table} component={Tables} />
      <Route exact path={schedule} component={SchedulePageModule} />
      <Route exact path={population} component={PopulationModule} />
      <Route exact path={energy} component={ChartPost} />
      <Route exact path={economy} component={EconomyPost} />
      <Route exact path={greenkaPolicy} component={PolicyPost} />
      <Route path='*' exact={true} component={NotFound} />
    </Switch>
  );
};

export default App;
