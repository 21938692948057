import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { shortSiteTitle, siteDescription, siteImage, siteTitle } from "../../config"


const SeoComponent = (props) => {

   const removeHTML = (str) => {
      var tmp = document.createElement("DIV");
      tmp.innerHTML = str;
      return tmp.textContent || tmp.innerText || "";
   }
   const postTitle = props ? props.postTitleData : null;
   const postDescription = props ? props.postDescriptionData : null;
   const postCover = props ? props.postCoverData : null;


   const title = postTitle ? `${postTitle} - ${shortSiteTitle}` : siteTitle;
   const description = postDescription ? removeHTML(postDescription) : siteDescription;
   const image = postCover ? postCover : siteImage;
   const url = window.location.href;

   return (<>
      <HelmetProvider>
         <Helmet>
            <title>{title.toString()}</title>
            <meta name="description" content={description.toString()} />
            <meta name="page-subject" content="Луцькводоканал" />

            <meta property="og:url" content={url.toString()} />
            <meta property="og:title" content={title.toString()} />
            <meta property="og:description" content={description.toString()} />
            <meta property="og:image:type" content="image/*" />
            <meta property="og:image" content={image.toString()} />
            <meta property="og:type" content="article" />
            <meta property="og:hashtag" content="#луцькводоканал" />
            <meta property="og:site_name" content="Луцькводоканал" />

            <meta property="twitter:site" content="Луцькводоканал" />
            <meta property="twitter:title" content={title.toString()} />
            <meta property="twitter:description" content={description.toString()} />
            <meta property="twitter:image" content={image.toString()} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:image:src" content={image.toString()} />


         </Helmet>
      </HelmetProvider>
   </>);
};

export default SeoComponent;