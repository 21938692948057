


import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faLocationDot, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import ModalContact from "./modal";
import MapContact from "./map";
import "./contacts_map.scss";

/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const ContactsMapComponent = () => {
  return (<div>
    <div className="contacts-map-container">
      <div className="container">
        <h1>Контакти</h1>
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="card" >
              <div className="card-body">
                <h5 className="card-title"><FontAwesomeIcon icon={faEnvelope} /></h5>
                <p className="card-text">Телефони</p>
                <div className="card-info">
                  <span>Кол-центр</span>
                  <a href="tel:0800104040">(0800)-10-40-40</a>
                  <span>Факс</span>
                  <a href="tel:0332284010">(0332)-28-40-10</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="card" >
              <div className="card-body">
                <h5 className="card-title"><FontAwesomeIcon icon={faPhone} /></h5>
                <p className="card-text">Пошта</p>
                <div className="card-info">
                  <span>Допомога</span>
                  <a href="mailto:info@vd.lutsk.ua">info@vd.lutsk.ua</a>
                  <span>Подача показників</span>
                  <a href="mailto:pokazvoda@gmail.com">pokazvoda@gmail.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="card" >
              <div className="card-body">
                <h5 className="card-title"><FontAwesomeIcon icon={faLocationDot} /></h5>
                <p className="card-text">Адреса</p>
                <div className="card-info">
                  <span>Головний офіс</span>
                  <div className="map-links">м. Луцьк вул. Дубнівська, 26</div>
                  <a href="https://goo.gl/maps/zgAr3akNSuNZaoc56" target="_blank" rel="noreferrer" className="map-links-map">Переглянути на карті <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="info-box">
                <h1>Зв’язатись через контактну форму</h1>
                <ModalContact />
               
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              {/* <div className="info-map">
                <MapContact />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default ContactsMapComponent;