import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./redux/store";
import FooterComponent from "./modules/global/components/footer/footer";
import Navbar from "./modules/global/components/nav/nav"
import { ToastContainer, Flip } from 'react-toastify';
import ToTop from "./toTop"

ReactDOM.render(
  <Provider store={store}>
        <Router>
          <Navbar />
          <App />
          <ToTop />
          <ToastContainer limit={4} transition={Flip} theme="colored" />
          <FooterComponent />
        </Router>
  </Provider>,
  document.getElementById("root")
);
reportWebVitals();

