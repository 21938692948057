import React from "react";
import "./cards.scss";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const CardsComponent = () => {
    const NotificationsData = useSelector(state => state.nav.notificationsList);

    const note = (name, type, body) => {
        const getType = (type) => {
            if (type === "Небезпека") {
                return "note-danger"
            }
            if (type === "Успіх") {
                return "note-success"
            }
            if (type === "Попередження") {
                return "note-warning"
            }
            else {
                return "note-info"
            }
        }
        return (<div className="note-container">
            <p className={`note ${getType(type)}`}>

                <strong>{name}</strong>
                <div dangerouslySetInnerHTML={{ __html: body }} />
            </p>
        </div>)
    }
    const SingleNotificationsDataRender = NotificationsData.map(item => {
        return (<SwiperSlide key={Math.random()}>{note(item.name, item.tags, item.body)} </SwiperSlide>)


    })
    const notificationsRender = () => {
        if (NotificationsData.length === 1) {
            return note(NotificationsData[0].name, NotificationsData[0].tags, NotificationsData[0].body)

        }
        if (NotificationsData.length === 0) {
            return
        }
        if (NotificationsData.length > 1) {
            return (<Swiper
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >

                {SingleNotificationsDataRender}
            </Swiper>)
        }
    }

    return (<div>
        
        <div className="cards-container">
        <div className="container">
        <div className="card pr-4 pl-4 newRelise">
        <br/>
        <h6>
        <b>Шановні користувачі, Ми з радістю повідомляємо, що наш сайт отримав оновлення і тепер доступна нова версія!</b>
        <br/>
        <br/>
        Cподіваємося, що вам сподобаються наші зміни та нововведення і ви будете продовжувати користуватися нашим сайтом з задоволенням.
        <p className="pt-2">З повагою, КП Луцькводоканал.</p>
        </h6>
        <div className="eddit"><p>Стара версія сайту доступна за <a target="_blank" href="http://old.vd.lutsk.ua/">даним посиланням</a>.</p>
        <p>Якщо ви виявили будь-які проблеми або помилки, будь ласка, надішліть нам повідомлення на електронну адресу <a href="mail:dev@vd.lutsk.ua">dev@vd.lutsk.ua</a>.</p></div>
        </div>
        </div>
            <br />
            {notificationsRender()}
            <div className="container">
                <div className="row">

                    <div className="col-lg-4 col-md-6">
                        <div className="card" >
                            <div className="card-body">
                                <h5 className="card-title">ВІТАЄМО НА САЙТІ КП "ЛУЦЬКВОДОКАНАЛ"</h5>
                                <p className="card-text">Для передачі показників та контролю станом рахунку реєструйтесь на сайті.</p>
                                <a href="https://oc.vd.lutsk.ua/" target="_blank" rel="noreferrer">Реєстрація <FontAwesomeIcon icon={faArrowRight} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card" >
                            <div className="card-body">
                                <h5 className="card-title">ПОДАТИ ПОКАЗНИК ЛІЧИЛЬНИКА МОЖНА ЗА ДОПОМОГОЮ КОЛ-ЦЕНТРУ</h5>
                                <p className="card-text">з 20 по 3 число за безкоштовним номером телефону (0800) 10-40-40</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card" >
                            <div className="card-body">
                                <h5 className="card-title">ЦЕНТР ОБСЛУГОВУВАННЯ КЛІЄНТІВ</h5>
                                <p className="card-text">Соборності, 25: Розрахунки за послуги, інформаційні послуги, договірні відносини, проектні роботи!</p>
                                <a href="https://goo.gl/maps/Qxxe6chmDb6rGffB8" target="_blank" rel="noreferrer">Переглянути на карті <FontAwesomeIcon icon={faArrowRight} /></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>


    </div>)

}

export default CardsComponent;