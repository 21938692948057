import {
    GET_PERSONAL_REQUEST,
    GET_PERSONAL_SUCCESS,
    GET_PERSONAL_FAILURE,
} from "../const";


const initialState = {
    personal: [],
    load: false
};

const handlers = {
    [GET_PERSONAL_REQUEST]: (state) => ({ ...state, load: false }),
    [GET_PERSONAL_SUCCESS]: (state, { payload: { personList } }) => ({
        ...state,
        load: true,
        ...personList
    }),
    [GET_PERSONAL_FAILURE]: (state) => ({ ...state, load: false }),
    DEFAULT: (state) => state,
};


const personalListReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default personalListReducer;