import { getPostCategoryListData } from "../api"
import { toastError, toastWarn } from "../../../modules/global/errors.toast";
import {
    GET_BLOG_CATEGORY_REQUEST,
    GET_BLOG_CATEGORY_SUCCESS,
    GET_BLOG_CATEGORY_FAILURE,
} from "../const";



const request = () => ({ type: GET_BLOG_CATEGORY_REQUEST });

const success = (postCategoryList, page) => ({
    type: GET_BLOG_CATEGORY_SUCCESS,
    payload: { postCategoryList, page},
});
const failure = () => ({ type: GET_BLOG_CATEGORY_FAILURE });

export const receivePostCategoryList = (params) => async (dispatch) => {
    dispatch(request());
    try {
        const { data: receivePostCategoryListData } = await getPostCategoryListData(params).catch((error)=> {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                }
            }})
        dispatch(success(receivePostCategoryListData, params.page));
    } catch (e) {
        dispatch(failure());
    };
};