import { getSinglePurchaseData } from "../api"
import { toastError, toastWarn } from "../../../modules/global/errors.toast";
import {
    GET_SINGLEPUR_REQUEST,
    GET_SINGLEPUR_SUCCESS,
    GET_SINGLEPUR_FAILURE,
} from "../const";



const request = () => ({ type: GET_SINGLEPUR_REQUEST });

const success = (OnePost, empty) => ({
    type: GET_SINGLEPUR_SUCCESS,
    payload: { OnePost, empty },
});
const failure = () => ({ type: GET_SINGLEPUR_FAILURE });

export const receiveSinglePurchase = (params) => async (dispatch) => {
    dispatch(request());
    try {
        getSinglePurchaseData(params).then(({ data: receiveOnePostData }) => {
            const is_empty = () => {
                return receiveOnePostData.length === 0 ? true : false;
            }
            dispatch(success(receiveOnePostData, is_empty()));
        }).catch((error) => {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                }
            }
        })

    } catch (e) {
        dispatch(failure());
    };
};