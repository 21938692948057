import React , {useState}from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import './modal.scss'

const ModalContact = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [ form, setForm ] = useState({})
    const [ errors, setErrors ] = useState({})
  
    const setField = (field, value) => {
      setForm({
        ...form,
        [field]: value
      })
      // Check and see if errors exist, and remove them from the error object:
      if ( !!errors[field] ) setErrors({
        ...errors,
        [field]: null
      })
    }
  
    const handleSubmit = e => {
      e.preventDefault()
      // get our new errors
      const newErrors = findFormErrors()
      // Conditional logic:
      if ( Object.keys(newErrors).length > 0 ) {
        // We got errors!
        setErrors(newErrors)
      } else {
        // No errors! Put any logic here for the form submission!
        alert('Thank you for your feedback!')
      }
    }
  
    const findFormErrors = () => {
      const { name, food, comment } = form
      const newErrors = {}
      if ( !name || name === '' ) newErrors.name = 'cannot be blank!'
      else if ( name.length > 30 ) newErrors.name = 'name is too long!'
      if ( !food || food === '' ) newErrors.food = 'select a food!'
      if ( !comment || comment === '' ) newErrors.comment = 'cannot be blank!'
      else if ( comment.length > 100 ) newErrors.comment = 'comment is too long!'
  
      return newErrors
    }

    return (
      <>
        <Button variant="primary" className="contact-btn"><a className="mailto" href="mailto:info@vd.lutsk.ua">
        Надіслати повідомлення</a>
      </Button>

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Контактна форма</Modal.Title>
          </Modal.Header>
          <Modal.Body>
<div className='App d-flex flex-column align-items-center'>
      <Form>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control 
            type='text' 
            onChange={ e => setField('name', e.target.value) }
            isInvalid={ !!errors.name }
          />
          <Form.Control.Feedback type='invalid'>{ errors.name }</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Food?</Form.Label>
          <Form.Control 
            as='select' 
            onChange={ e => setField('food', e.target.value) }
            isInvalid={ !!errors.food }
          >
            <option value=''>Select a food:</option>
            <option value='chicken parm'>Chicken Parm</option>
            <option value='BLT'>BLT</option>
            <option value='steak'>Steak</option>
            <option value='salad'>Salad</option>
          </Form.Control>
          <Form.Control.Feedback type='invalid'>{ errors.food }</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Comments</Form.Label>
          <Form.Control 
            as='textarea' 
            onChange={ e => setField('comment', e.target.value) }
            isInvalid={ !!errors.comment }
            />
            <Form.Control.Feedback type='invalid'>{ errors.comment }</Form.Control.Feedback>
        </Form.Group>
      </Form>
    </div>
          </Modal.Body>
          <Modal.Footer>
          <Button className="button-contact" type='submit' onClick={ handleSubmit }>Відправити</Button>
            <Button className="button-contact red" onClick={handleClose}>
              Закрити
            </Button>
            
          </Modal.Footer>
        </Modal>
      </>
    );
  }


  export default ModalContact;