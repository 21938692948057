import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receiveTables } from "./actions/TableActions"
import { MDBDataTable } from 'mdbreact';
import SearchItem from "../global/components/search/search";
import SocialMediaButtons from "../global/shareSocial";
import post_placeholder from "../../assets/images/post-placeholder.png"
import "./index.scss"


/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const Tables = () => {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const postId = urlParams.get('id');
    const url = window.location.href;
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(receiveTables({ id: postId }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postId]);
    const tableData = useSelector(state => state.tables);
    return (<>
        <div className="table-post">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-sm-12">
                        <div className="container">
                        
                            <div className="row">
                            <SocialMediaButtons url={url} classname="share-buttons-page" />
                                <div className="card w-100 h-100">
                                    <div className="card-body">
                                        <h4 className="card-title">{tableData.title}</h4>
                                        <div className="card-text">
                                            <MDBDataTable
                                                striped hover displayEntries={false} entries={10} pagesAmount={7}
                                                data={tableData} className="w-100" searchLabel="Пошук" infoLabel={["", "-", "з", ""]} paginationLabel={['Назад', 'Далі']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-12 widget-column">
                        <SearchItem />
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Tables;




