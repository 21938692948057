import {
    GET_NAV_REQUEST,
    GET_NAV_SUCCESS,
    GET_NAV_FAILURE,
} from "../const";


const initialState = {
    menu: [],
    notifications: 0,
    notificationsList: []
};

const handlers = {
    [GET_NAV_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_NAV_SUCCESS]: (state, { payload: { menu } }) => ({
        ...state,
        loading: false,
        ...menu,
    }),
    [GET_NAV_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};

const navReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default navReducer;