import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receivePerson } from "./actions/PersonActions"
import LazyLoad from 'react-lazyload';
import SearchItem from "../global/components/search/search"
import NotFound from "../notFound404"
import "./index.scss"

/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const Person = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const postId = urlParams.get('id') === null ? -1 : urlParams.get('id');

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(receivePerson({ id: postId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  const singlePersonData = useSelector(state => state.person);

  return !singlePersonData[0].code && singlePersonData?.load ? <NotFound /> : (<div>
    <div className="person-post">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-sm-12">
            {singlePersonData.load ?
              <div className="card mt-4">
                <div className="row">
                  <div className="col-xl-4 col-sm-12">
                    <div className="img-square-wrapper">
                      <LazyLoad><img className="w-100" src={singlePersonData[0]?.image} alt={singlePersonData[0]?.name} /></LazyLoad>
                    </div>
                  </div>
                  <div className="col-xl-8 col-sm-12">
                    <div className="person-header">
                      <h4 className="card-title">{singlePersonData[0].name}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body  h-100">
                  <p className="card-text" dangerouslySetInnerHTML={{ __html: singlePersonData[0].body }} />
                </div>
              </div> : null}
          </div>
          <div className="col-xl-4 col-md-12 widget-column mt-0">
            <SearchItem />
          </div>
        </div>
      </div>
    </div>

  </div>)
}

export default Person;




