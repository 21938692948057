/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import "./index.scss";
import Slider from "../../components/slider/slider";
import CardsComponent from "../global/components/cards/cards";
import Cabinet from "../../components/cabinet/cabinet";
import BlogComponent from "../../components/bloghome/blog";
import SeoComponent from "../global/shareMeta";
/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const HomePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (<div>
    <SeoComponent/>
    <Slider />
    <CardsComponent />
    <Cabinet />
    <BlogComponent />


  </div>)

}

export default HomePage;


