import { getPersonalListData } from "../api"
import { toastError, toastWarn } from "../../global/errors.toast";
import {
    GET_PERSONAL_REQUEST,
    GET_PERSONAL_SUCCESS,
    GET_PERSONAL_FAILURE,
} from "../const";


const request = () => ({ type: GET_PERSONAL_REQUEST});

const success = (personList) => ({
    type: GET_PERSONAL_SUCCESS,
    payload: { personList },
});
const failure = () => ({ type: GET_PERSONAL_FAILURE});

export const receivePersonalList = () => async (dispatch) => {
    dispatch(request());
    try {
        const { data: receivePersonalListData} = await getPersonalListData().catch((error)=> {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                }
            }})

        dispatch(success(receivePersonalListData));
    } catch (e) {
        dispatch(failure());
    };
};