import { getStationData } from "../api"
import { toastError, toastWarn } from "../../../modules/global/errors.toast";
import {
    GET_STATION_REQUEST,
    GET_STATION_SUCCESS,
    GET_STATION_FAILURE,
} from "../const";


const request = () => ({ type: GET_STATION_REQUEST });

const success = (station) => ({
    type: GET_STATION_SUCCESS,
    payload: { station },
});
const failure = () => ({ type: GET_STATION_FAILURE });

export const receiveStation = () => async (dispatch) => {
    dispatch(request());
    try {
        const { data: receiveStationData } = await getStationData().catch((error) => {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                } else {
                    if (error.response.status === 403) {
                        toastError(`${error.response.status} Station is Offline`)
                    }
                }
            } 
        })

        dispatch(success(receiveStationData));
    } catch (e) {
        dispatch(failure());
    };
};