import {
    GET_BLOG_REQUEST,
    GET_BLOG_SUCCESS,
    GET_BLOG_FAILURE,
} from "../const";


const initialState = {
    posts: []
};
const getPosts = ({ state, posts, page}) => {
    return page !== 1 ? [...state.posts, ...posts.posts] : [...posts.posts];
}
const handlers = {
    [GET_BLOG_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_BLOG_SUCCESS]: (state, { payload: { posts, page } }) => ({
        ...state,
        loading: false,
        total: posts.total,
        posts: getPosts({state, posts, page}),
    }),
    [GET_BLOG_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};


const homePostReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default homePostReducer;