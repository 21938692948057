import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receiveNav } from "./actions/NavActions";
import { MAIN_NAV_ID } from "../../../../config"
import NavbarMain from "./components/navMain";
import TopBar from "./components/topBar";
import { receiveAlerts } from "./actions/AlertsActions";
import "./nav.scss";


const NavbarHeader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(receiveNav({ id: MAIN_NAV_ID }));
    dispatch(receiveAlerts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navData = useSelector(state => state.nav);
  const alertsData = useSelector(state => state.alerts);


  return (<>
    <NavbarMain navData={navData.menu} notifications={navData.notifications} notificationsList={navData.notificationsList} alertsData={alertsData}>
      <TopBar />
    </NavbarMain>
  </>);
}

export default NavbarHeader;


