import { getPurchasesData  } from "../api"
import { toastError, toastWarn } from "../../global/errors.toast";
import {
    GET_PUR_REQUEST,
    GET_PUR_SUCCESS,
    GET_PUR_FAILURE,
} from "../const";


const request = () => ({ type: GET_PUR_REQUEST});

const success = (posts, page) => ({
    type: GET_PUR_SUCCESS,
    payload: { posts, page },
});
const failure = () => ({ type: GET_PUR_FAILURE});

export const receivePurchases = (params) => async (dispatch) => {
    dispatch(request());
    try {
        const { data: receivePostData } = await getPurchasesData(params).catch((error)=> {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                }
            }})
        dispatch(success(receivePostData, params.page));
    } catch (e) {
        
        dispatch(failure());
    };
};