import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faViber, faTelegram } from "@fortawesome/free-brands-svg-icons";
import Modal from 'react-bootstrap/Modal';
import viber_qr from "../../../../../assets/images/viber_QR.png"
import telegram_qr from "../../../../../assets/svg/teltegram_QR.svg"

const ViberModal = (props) => {
    return (
        <Modal {...props}
            dialogClassName="modal-width-container"
            aria-labelledby="contained-modal-title-vcenter"
            centered >
            <Modal.Header>
                Viber-Бот
            </Modal.Header>
            <Modal.Body>
                    <img src={viber_qr} className="w-100" alt="viber" />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <a href="viber://pa?chatURI=lutskvodokanalbot" className="link" rel="noreferrer">Перейти за посиланням</a>
            </Modal.Footer>
        </Modal>
    );
}
const TelegramModal = (props) => {
    return (
        <Modal {...props}
            dialogClassName="modal-width-container"
            aria-labelledby="contained-modal-title-vcenter"
            centered >
            <Modal.Header>
                Telegram-Бот
            </Modal.Header>
            <Modal.Body>
                    <img src={telegram_qr} className="w-100" alt="telegram" />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <a href="https://t.me/lutskvodokanal_bot" className="link" target="_blank" rel="noreferrer">Перейти за посиланням</a>
            </Modal.Footer>
        </Modal>
    );
}

const TopBar = () => {
    const [modalViberShow, setViberModalShow] = useState(false);
    const [modalTelegramShow, setTelegramModalShow] = useState(false);
    return (<>
        <div className="top-bar">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <a href="mailto:pokazvoda@gmail.com"><span className="mr-2"><FontAwesomeIcon icon={faEnvelope} /></span> <span
                            className="d-none d-md-inline-block">pokazvoda@gmail.com</span></a>
                        <span className="mx-md-2 d-inline-block"></span>
                        <a href="tel:0800104040"><span className="mr-2"><FontAwesomeIcon icon={faPhone} /></span><span
                            className="d-none d-md-inline-block">(0800)10-40-40</span></a>
                        <div className="float-right">
                            <a onClick={() => setViberModalShow(true)}><span className="mr-2"> <FontAwesomeIcon icon={faViber} /></span> <span
                                className="d-none d-md-inline-block">Viber-Бот</span></a>
                            <span className="mx-md-2 d-inline-block"></span>
                            <a onClick={() => setTelegramModalShow(true)}><span className="mr-2"><FontAwesomeIcon icon={faTelegram} /></span> <span
                                className="d-none d-md-inline-block">Telegram-Бот</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ViberModal
            show={modalViberShow}
            onHide={() => setViberModalShow(false)}
        />
        <TelegramModal
            show={modalTelegramShow}
            onHide={() => setTelegramModalShow(false)}
        />
    </>);
}

export default TopBar;