


import React from "react";
import Accordion from 'react-bootstrap/Accordion'
import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import "./collapse.scss";
/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */
const data = [
  {
    department: {
      name: "АДМІНІСТРАЦІЯ",
      data: [
        {
          part: "Приймальна, Діловод",
          name: "Калінчук Оксана Анатоліївна",
          phone: "28-40-00"
        },
      ]
    }
  },

  {
    department: {
      name: "БУХГАЛТЕРІЯ",
      data: [
        {
          part: "Головний бухгалтер",
          name: "Шпур Тетяна Іванівна",
          phone: "-"
        },
        {
          part: "Заступник головного бухгалтера",
          name: "Гаюк Ірина Юріївна",
          phone: "28-40-34"
        },
        {
          part: "Бухгалтер",
          name: "Красун Юлія Володимирівна",
          phone: "28-40-11"
        },
        {
          part: "Бухгалтер",
          name: "Андрійчук Валентина Олексіївна",
          phone: "28-40-11"
        },
        {
          part: "Бухгалтер",
          name: "Гаврилюк Оксана Володимирівна",
          phone: "28-40-12"
        },
        {
          part: "Бухгалтер",
          name: "Лисюк Катерина Борисівна",
          phone: "28-40-11"
        },
        {
          part: "Бухгалтер",
          name: "Литвинчук Оксана Миколаївна",
          phone: "28-40-11"
        },
        {
          part: "Бухгалтер",
          name: "Рудчук Інна Сергіївна",
          phone: "28-40-11"
        },
        {
          part: "Бухгалтер",
          name: "Троцюк Ірина Леонідівна",
          phone: "28-40-11"
        }
      ]
    }
  },
  {
    department: {
      name: "ЮРИДИЧНИЙ ВІДДІЛ",
      data: [
        {
          part: "Начальник відділу",
          name: "Мисюк Ганна Іванівна",
          phone: "28-40-42"
        },
        {
          part: "Юрисконсульт",
          name: "Кравчук Інна Миколаївна",
          phone: "28-40-42"
        }
      ]
    }
  },
  {
    department: {
      name: "ПЛАНОВО-ЕКОНОМІЧНИЙ ВІДДІЛ",
      data: [
        {
          part: "Економіст",
          name: "Степанюк Світлана Анатоліївна",
          phone: "28-40-33"
        },
        {
          part: "Економіст",
          name: "Кондратюк Тетяна Михайлівна",
          phone: "28-40-33"
        },
        {
          part: "Економіст",
          name: "Тюкова Наталія Вікторівна",
          phone: "28-40-33"
        },
      ]
    }
  },
  {
    department: {
      name: "ВІДДІЛ КАДРІВ",
      data: [
        {
          part: "Начальник",
          name: "Кульчинська Ірина Вікторівна",
          phone: "28-40-27"
        },
        {
          part: "Інженер з кадрів",
          name: "Подолець Анжела Валеріївна",
          phone: "28-40-27"
        }
      ]
    }
  },
  {
    department: {
      name: "ТЕХНІЧНЕ УПРАВЛІННЯ",
      data: [
        {
          part: "Начальник управління",
          name: "Корчук Микола Іванович",
          phone: "28-40-09"
        },
        {
          part: "Начальник відділу технічного аудиту",
          name: "Петрук Микола Ніконович",
          phone: "28-40-60"
        },
        {
          part: "Інженер з контролю за водокористуванням",
          name: "Кирильчук Л.М",
          phone: "28-40-62"
        },
        {
          part: "Інженер з контролю за водокористуванням",
          name: "Пастущук Я.Ю",
          phone: "28-40-62"
        },
        {
          part: "Інженер з контролю за водокористуванням",
          name: "Бойчук Дмитро Петрович",
          phone: "28-40-62"
        },
        {
          part: "Інженер з контролю за водокористуванням",
          name: "Гринюк Олена Володимирівна",
          phone: "28-40-62"
        },
        {
          part: "Інженер з контролю за водокористуванням",
          name: "Жук Юрій Григорович",
          phone: "28-40-62"
        },
        {
          part: "Інженер з контролю за водокористуванням",
          name: "Ковальчук Микола Олександрович",
          phone: "28-40-62"
        },
        {
          part: "Інженер з контролю за водокористуванням",
          name: "Мартинюк Василь Вікторович",
          phone: "28-40-62"
        },
        {
          part: "Інженер з контролю за водокористуванням",
          name: "Миколайчук Назар Олегович",
          phone: "28-40-62"
        },
        {
          part: "Інженер з контролю за водокористуванням",
          name: "Піддубна Ірина Володимирівна",
          phone: "28-40-62"
        }
      ]
    }
  },
  {
    department: {
      name: "ВІДДІЛ БАЛАНСОВОГО КОНТРОЛЮ ТА АВТОМАТИЗАЦІЇ ОБЛІКУ",
      data: [
        {
          part: "Начальник",
          name: "Панасюк Вадим Степанович",
          phone: "-"
        },
        {
          part: "Інженер",
          name: "Лойко Павло Анатолійович",
          phone: "-"
        },
        {
          part: "Інженер",
          name: "Кінюк Ярослав Валерійович",
          phone: "-"
        },
        {
          part: "Інженер",
          name: "Лойко Павло Анатолійович",
          phone: "-"
        }
      ]
    }
  },
  {
    department: {
      name: "КОМЕРЦІЙНЕ УПРАВЛІННЯ",
      data: [
        {
          part: "Начальник управління",
          name: "Логін Лілія Миколаївна",
          phone: "28-40-07"
        }
      ]
    }
  },
  {
    department: {
      name: "ВІДДІЛ ПО РОЗРАХУНКАХ З ЮРИДИЧНИМИ СПОЖИВАЧАМИ",
      data: [
        {
          part: "Начальник",
          name: "Московчук Людмила Вікторівна",
          phone: "28-40-46"
        },
        {
          part: "Економіст зі збуту",
          name: "Ковальчук Наталія Василівна",
          phone: "28-40-58"
        },
        {
          part: "Оператори ЕОМ та ОМ",
          name: "Мазурок Марія Павлівна",
          phone: "28-40-45"
        },
        {
          part: "Оператори ЕОМ та ОМ",
          name: "Зубовець Ірина Володимирівна",
          phone: "28-40-44"
        },
        {
          part: "Економіст зі збуту",
          name: "Павлюк Галина Георгіївна",
          phone: "-"
        },
        {
          part: "Оператор ЕОМ та ОМ",
          name: "Петрук Віра Львівна",
          phone: "28-40-45"
        },
        {
          part: "Економіст зі збуту",
          name: "Теодорович Наталія Іванівна",
          phone: "-"
        }
      ]
    }
  },
  {
    department: {
      name: "ВІДДІЛ ПО РОБОТІ З ПОБУТОВИМИ СПОЖИВАЧАМИ",
      data: [
        {
          part: "Начальник",
          name: "Коваль Вікторія Вікторівна",
          phone: "28-40-41"
        },
        {
          part: "Економіст зі збуту",
          name: "Кокоць Лілія Юріївна",
          phone: "-"
        },
        {
          part: "Економіст дільниці обліку реалізації",
          name: "Романюк Ірина Анатоліївна",
          phone: "-"
        },
        {
          part: "Оператор з введення даних в ЕОМ(МО)",
          name: "Гресик Ірина Олексіївна",
          phone: "-"
        },
        {
          part: "Оператор з введення даних в ЕОМ(МО)",
          name: "Ковальчук Інна Олесандрівна",
          phone: "-"
        },
        {
          part: "Оператор з введення даних в ЕОМ(МО)",
          name: "Маковська Олена Олександрівна",
          phone: "-"
        },
        {
          part: "Економіст із збуту",
          name: "Остапук Ірина Вікторівна",
          phone: "-"
        },
        {
          part: "Оператор з введення даних в ЕОМ(МО)",
          name: "Парфенюк Лариса Володимирівна",
          phone: "-"
        },
        {
          part: "Оператор з введення даних в ЕОМ(МО)",
          name: "Тандрик Марія Юріївна",
          phone: "-"
        },
        {
          part: "Оператор з введення даних в ЕОМ(МО)",
          name: "Тарасюк Олеся Миколаївна",
          phone: "-"
        },
        {
          part: "Економіст із збуту",
          name: "Халан Ольга Вікторівна",
          phone: "-"
        },
        {
          part: "Оператор з введення даних в ЕОМ(МО)",
          name: "Шенгелевич Тетяна Степанівна",
          phone: "-"
        }
      ]
    }
  },
  {
    department: {
      name: "ВІДДІЛ ІНФОРМАЦІЙНИХ ТЕХНОЛОГІЙ",
      data: [
        {
          part: "Начальник відділу",
          name: "Пушкарський Андрій Ростиславович",
          phone: "28-40-06"
        },
        {
          part: "Інженер - програміст",
          name: "Арсенюк Василь Миколайович",
          phone: "28-40-06"
        },
        {
          part: "Інженер - програміст",
          name: "Гуменюк Максим Сергійович",
          phone: "28-40-06"
        },
        {
          part: "Інженер - програміст",
          name: "Грешта Сергій Семенович",
          phone: "-"
        },
      ]
    }
  },
  {
    department: {
      name: "Відділ автоматизації систем управління(АСУ)",
      data: [
        {
          part: "Інженер",
          name: "Павлюк Назар Вікторович",
          phone: "-"
        },
      ]
    }
  },
  {
    department: {
      name: "ВІДДІЛ З ОБСЛУГОВУВАННЯ КЛІЄНТІВ",
      data: [
        {
          part: "Начальник",
          name: "Цвяк Наталія Віталіївна",
          phone: "-"
        },
        {
          part: "Адміністратор",
          name: "Каліх Світлана Миколаївна",
          phone: "-"
        },
        {
          part: "Адміністратор",
          name: "Крохмаль Валентина Валентинівна",
          phone: "-"
        },
        {
          part: "Адміністратор",
          name: "Сидорова Марія Юріївна",
          phone: "-"
        },
        {
          part: "Адміністратор",
          name: "Лазорко Анна Петрівна",
          phone: "-"
        },
        {
          part: "Старший адміністратор",
          name: "Леміш Олена Леонідівна",
          phone: "-"
        },
        {
          part: "Адміністратор",
          name: "Поворознюк Світлана Іванівна",
          phone: "-"
        },
        {
          part: "Адміністратор",
          name: "Фесіна Руслана Степанівна",
          phone: "-"
        }
        
      ]
    }
  },
   {
    department: {
      name: "ВИРОБНИЧО-ТЕХНІЧНИЙ ВІДДІЛ",
      data: [
        {
          part: "Начальник відділу",
          name: "Цвяк Віктор Миколайович",
          phone: "28-40-20"
        },
        {
          part: "Заступник начальника відділу",
          name: "Покотило Павло Антонович",
          phone: "28-40-32"
        },
        {
          part: "Інженер з проектно-кошторисної документації",
          name: "Грабаровська Наталія Миколаївна",
          phone: "28-40-32"
        },
        {
          part: "Інженер з охорони НС",
          name: "Макара Іванна Валеріївна",
          phone: "28-40-32"
        },
        {
          part: "Інженер - проектувальник",
          name: "Літвицька Ірина Григорівна",
          phone: "28-40-21"
        },
        {
          part: "Інженер - проектувальник",
          name: "Вега Іванна Михайлівна",
          phone: "28-40-21"
        },
        {
          part: "Інженер",
          name: "Герман Наталія Володимирівна",
          phone: "28-40-32"
        },
        {
          part: "Інженер",
          name: "Максимчук Микола Юрійович",
          phone: "28-40-32"
        },
        {
          part: "Інженер з проектно-кошторисної документації",
          name: "Фурс Вадим Валерійович",
          phone: "28-40-32"
        }
      ]
    }
  },
  {
    department: {
      name: "ХІМІКО-БАКТЕРІОЛОГІЧНА ЛАБОРАТОРІЯ",
      data: [
        {
          part: "Начальник",
          name: "Сокол Алла Анатоліївна",
          phone: "28-40-24"
        },
        {
          part: "Лабораторія очисних споруд",
          name: "Денисюк Інна Ростиславівна",
          phone: "29-07-35"
        },
        {
          part: "Лабораторія ГНС № 4",
          name: "Лавренюк Майя Іванівна",
          phone: "29-69-05"
        },
      ]
    }
  },
  {
    department: {
      name: "Сектор комплектації",
      data: [
        {
          part: "Начальник сектору",
          name: "Гапонюк Олена Валентинівна",
          phone: "28-40-31"
        },
        {
          part: "Інженер",
          name: "Шевчук Олександр Петрович",
          phone: "28-40-31"
        },
        {
          part: "Інженер",
          name: "Кравцова Алла Миколаївна",
          phone: "28-40-31"
        },
        {
          part: "Інженер",
          name: "Грицай Ігор Євгенійович",
          phone: "-"
        },
        {
          part: "Товарознавець",
          name: "Линюк Наталія Анатоліївна",
          phone: "-"
        },
      ]
    }
  },
  {
    department: {
      name: "Відділ комплектації та закупівель",
      data: [
        {
          part: "Начальник відділу",
          name: "Пасічник Наталія Олексіївна",
          phone: "-"
        },
      ]
    }
  },
  {
    department: {
      name: "Сектор закупівель",
      data: [
        {
          part: "Начальник відділу",
          name: "Фещук Микола Петрович",
          phone: "-"
        },
        {
          part: "Фахівець з публічних закупівель",
          name: "Вакула Яна Олександрівна",
          phone: "-"
        }
      ]
    }
  },
  {
    department: {
      name: "ВІДДІЛ ОХОРОНИ ПРАЦІ",
      data: [
        {
          part: "Начальник відділу",
          name: "Стасіцька Олена Миколаївна",
          phone: "28-40-28",
        },
        {
          part: "Заступник начальника відділу",
          name: "Боярчук Андрій Миколайович",
          phone: "28-40-36",
        },
        {
          part: "Інженер з охорони праці",
          name: "Крась Людмила Миколаївна",
          phone: "28-40-36"
        },
        {
          part: "Інженер з охорони праці",
          name: "Бойчук Петро Дмитрович",
          phone: "28-40-36"
        },
        {
          part: "Фізкабінет",
          name: "Борисюк Лариса Іванівна",
          phone: "29-07-36"
        },
      ]
    }
  },
  {
    department: {
      name: "ВІДДІЛ ГОЛОВНОГО ЕНЕРГЕТИКА",
      data: [
        {
          part: "Головний енергетик",
          name: "Шпак Сергій Анатолійович",
          phone: "29-69-02"
        },
        {
          part: "Заст. головного енергетика",
          name: "Неспай Володимир Анатолійович",
          phone: "28-40-22"
        },
        {
          part: "Електролабораторія",
          name: "Гнаткевич Геннадій Ростиславович",
          phone: "29-07-41"
        },
        {
          part: "Лабораторія КВПіА",
          name: "Франков Володимир Михайлович",
          phone: "29-07-41"
        },
        {
          part: "Черговий електрик",
          name: "Ставецький Олександр Васильович",
          phone: "29-69-21"
        },
      ]
    }
  },
  {
    department: {
      name: "Відділ охорони об’єктів",
      data: [
        {
          part: "Начальник",
          name: "Чуль Михайло Петрович",
          phone: "29-69-17"
        },

      ]
    }
  },
  {
    department: {
      name: "ДИСПЕТЧЕРСЬКА СЛУЖБА",
      data: [
        {
          part: "Начальник",
          name: "Гресик Мирослава Іванівна",
          phone: "15-53"
        },
        {
          part: "Диспетчерська",
          name: "-",
          phone: "25-55-55"
        },
      ]
    }
  },
  {
    department: {
      name: "Управління механізації і транспорту",
      data: [
        {
          part: "Начальник управління",
          name: "Бащук Дмитро Анатолійович",
          phone: "75-19-29",
        },
        {
          part: "Заступник начальника",
          name: "Бугай Валерій Григорович",
          phone: "75-19-29",
        },
        {
          part: "Диспетчерська",
          name: "-",
          phone: "75-19-29"
        },
      ]
    }
  },
  {
    department: {
      name: "ЦЕХ ВОДОПРОВІДНО-КАНАЛІЗАЦІЙНИХ МЕРЕЖ",
      data: [
        {
          part: "Начальник цеху",
          name: "Рижук Микола Васильович",
          phone: "75-10-41"
        },
        {
          part: "Заступник начальника",
          name: "Волох Світлана Анатоліївна",
          phone: "75-10-41"
        }
      ]
    }
  },
  {
    department: {
      name: "ЦЕХ НАСОСНИХ ТА ОЧИСНИХ СПОРУД ВОДОПРОВОДУ",
      data: [
        {
          part: "Начальник",
          name: "Деревянчук Ірина Василівна",
          phone: "28-40-26"
        },
        {
          part: "Заступник начальника",
          name: "Сухалевич Анатолій Юліанович",
          phone: "28-40-19"
        },
      ]
    }
  },
  {
    department: {
      name: "ЦЕХ НАСОСНИХ ТА ОЧИСНИХ СПОРУД КАНАЛІЗАЦІЇ",
      data: [
        {
          part: "Начальник",
          name: "Боярчук Ігор Миколайович",
          phone: "29-07-31"
        },
        {
          part: "Заступник начальника ОС",
          name: "Власюк Олександр Петрович",
          phone: "29-07-32"
        },
        {
          part: "Заступник начальника НСК",
          name: "Сацюк Тетяна Анатоліївна",
          phone: "24-56-37",
        },
      ]
    }
  },
  {
    department: {
      name: "ДІЛЬНИЦЯ ПО РЕМОНТУ ТА ТЕХНІЧНОМУ ОБСЛУГОВУВАННЮ БУДІВЕЛЬ",
      data: [
        {
          part: "Начальник управління",
          name: "Ковалик Олександр Костянтинович",
          phone: "-"
        },
        {
          part: "Майстер",
          name: "Ковальчук Олексій Сергійович",
          phone: "-"
        },
      ]
    }
  },
  {
    department: {
      name: "АДМІНІСТРАТИВНО-ГОСПОДАРСЬКИЙ ВІДДІЛ",
      data: [
        {
          part: "Завідувач господарства",
          name: "Качмар Вікторія Володимирівна",
          phone: "28-40-29"
        },
      ]
    }
  },

]


const CollapceComponent = () => {
  return (<div>

    <div className="collapse-container">

      <div className="container">
        <div className="table-container">
        <h3>Графік прийому громадян</h3>
        <hr/>
          <div className="card">
            <MDBTable striped hover>
              <MDBTableHead>
                <tr>
                  <th scope='col' colSpan={2}> <b>Прізвище, ім’я, по-батькові</b></th>
                  <th scope='col' colSpan={2}><b>Посада</b></th>
                  <th scope='col'><b>Дні прийому</b></th>
                  <th scope='col'><b>Години</b></th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr>
                  <th scope='row' colSpan={2}>Гуменюк Віктор Миколайович</th>
                  <td colSpan={2}>Директор</td>
                  <td>Понеділок</td>
                  <td>9.00-11.00</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Строк Сергій Борисович</th>
                  <td colSpan={2}>Головний інженер</td>
                  <td>Вівторок</td>
                  <td>9.00-11.00</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Дмитроца Святослав Семенович</th>
                  <td colSpan={2}>Перший заступник директора</td>
                  <td>Середа</td>
                  <td>9.00-11.00</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Чупун Володимир Анатолійович</th>
                  <td colSpan={2}>Заступник директора</td>
                  <td>Понеділок</td>
                  <td>9.00-11.00</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Марчук Анатолій Зіновійович</th>
                  <td colSpan={2}>Заступник директора з маркетингу та управління інвестиційною діяльністю</td>
                  <td>Четвер</td>
                  <td>9.00-11.00</td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </div>
          <h3>Керівництво</h3>
          <hr/>
          <div className="card">
            <MDBTable striped hover>
              <MDBTableBody>
                <tr>
                  <th scope='row' colSpan={2}>Директор</th>
                  <td colSpan={2}>Гуменюк Віктор Миколайович</td>
                  <td>28 40 00</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Головний інженер</th>
                  <td colSpan={2}>Строк Сергій Борисович</td>
                  <td>28 40 02</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Перший заступник директора</th>
                  <td colSpan={2}>Дмитроца Святослав Семенович</td>
                  <td>28 40 61</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Заступник директора</th>
                  <td colSpan={2}>Чупун Володимир Анатолійович</td>
                  <td>25 60 65</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Заступник диретора фінансовий(та з управління персоналом)</th>
                  <td colSpan={2}>Шевчук Ольга Олександрівна</td>
                  <td>28-40-18</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Заступник директора з маркетингу та управління інвестиційною діяльністю</th>
                  <td colSpan={2}>Марчук Анатолій Зіновійович</td>
                  <td>28 40 30</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Заступник директора з інформаційних технологій</th>
                  <td colSpan={2}>Назарук Олександр Борисович</td>
                  <td>28 40 39</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={2}>Заступник головного інженера</th>
                  <td colSpan={2}>Вега Юрій Васильович</td>
                  <td>28 40 08</td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </div>
        </div>
          <h3>Графік прийому споживачів</h3>
          <hr/>
        <div className="card">
            <MDBTable striped hover>
              <MDBTableBody>
                <tr>
                  <th scope='row' colSpan={3}>Відділ з обслуговування клієнтів</th>
                  <td>Графік роботи</td>
                  <td>Телефони</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={3}>Центр обслуговування клієнтів КП "Луцькводоканал" м. Луцьк, вул. Соборності, 25</th>
                  <td><b>Пн. - Чт.</b> <br/>з 8:00 до 18:00
                    <br/> <b>Пт. </b><br/>з 8:00 до 17:00</td>
                  <td>28 40 40</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={3}>Муніципальний сервісний центр м. Луцьк, вул. Винниченка, 26 (бізнес центр "Директорія")</th>
                  <td >Пн.-Пт. <br/>з 8:00 до 17:00</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope='row' colSpan={3}> Звірка стану особового рахунку</th>
                  <td></td>
                  <td>28 40 40</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={3}>Переоформлення особового рахунку (зміна власника квартири)</th>
                  <td></td>
                  <td>28 40 40</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={3}>Отримання довідки на оформлення пільг і субсидії</th>
                  <td></td>
                  <td>28 40 40</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={3}>Отримання письмової довідки про заборгованість</th>
                  <td></td>
                  <td>28 40 40</td>
                </tr>
                <tr>
                  <th scope='row' colSpan={3}>Укладання договорів про надання  послуг з централізованого водопостачання та водовідведення</th>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th scope='row' colSpan={3}>Укладання договорів на реструктуризацію боргу (населення)</th>
                  <td></td>
                  <td>28 40 40</td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </div>
          <br/>
          <br/>
        <Accordion defaultActiveKey={0} alwaysOpen>
          {data.map((item, index) => {
            return (
              <Accordion.Item eventKey={index}>
                <Accordion.Header>{item.department.name}</Accordion.Header>
                <Accordion.Body>
                  {item.department.data.map((row) => {
                    return (
                      <div className="row">
                        <div className="col-5">{row.part}</div>
                        <div className="col-5"><span>{row.name}</span></div>
                        <div className="col-2"><span>{row.phone}</span></div>
                        <hr />
                      </div>
                    )
                  })}
                </Accordion.Body>
              </Accordion.Item>)
          })}
        </Accordion>
      </div>
    </div>
  </div>)
}

export default CollapceComponent;