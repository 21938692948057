import React, { useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import { receiveFooterNav } from "./actions/FooterNavActions";
import "./footer.scss";
import icon1 from "../../../../assets/images/icon1.png";
import icon2 from "../../../../assets/images/icon2.png";
import icon3 from "../../../../assets/images/icon3.png";
import icon4 from "../../../../assets/images/icon4.png";
import main_logo from "../../../../assets/images/logo.png";
import { FOOTER_NAV_ID } from "../../../../config"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";




const FooterComponent = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(receiveFooterNav({ id: FOOTER_NAV_ID}));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const footerData = useSelector(state => state.footer.menu);
    const check_url = (url, name) => {
        if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
            return (<a key={Math.random()} href={url} target="_blank">{name}</a>)
        }
        else {
            return (<Link key={Math.random()} to={url}>{name}</Link>)
        }
    }

    const renderFooter = footerData.map(item => {
            return (<div key={Math.random()} className="col-foo col-xl-3 col-sm-6 col-xs-6">
            <h4>{item.name}</h4>
            <ul className="list-group list-group-flush">
                {item.sub.map(item => {
                    return (<li className="list-group-item" key={Math.random()}>{check_url(item.url, item.name)}</li>
                    )
                })}
            </ul></div>)
    })
        return (<div>
            <div className="footer-main">
                <div className="container">
                    <div className="row">
                        <div className="col-foo col-xl-3 col-sm-12 col-xs-12">
                        <a href={'https://volynrada.gov.ua/'} className="link" target="_blank" rel="noreferrer">
                        <div className="block">
                                <img src={icon1} alt="" />
                                <h5>ОРГАНИ ДЕРЖАВНОЇ ВЛАДИ</h5>
                            </div>
                            </a>
                        </div>
                        <div className="col-foo  col-xl-3 col-sm-12 col-xs-12">
                        <Link to={'/blog/post?id=710'}>
                            <div className="block">
                                <img src={icon2} alt="" />
                                <h5>ЖИТЛОВО-КОМУНАЛЬНІ ПІДПРИЄМСТВА М. ЛУЦЬКА </h5>
                            </div>
                            </Link>
                        </div>
                        <div className="col-foo  col-xl-3 col-sm-12 col-xs-12">
                        <a href={'http://jkg-portal.com.ua/'} className="link" target="_blank" rel="noreferrer">
                            <div className="block">
                                <img src={icon3} alt="" />
                                <h5>ІНФОРМАЦІЙНЕ АГЕНСТВО "УКРАЇНА КОМУНАЛЬНА"</h5>
                            </div>
                            </a>
                        </div>
                        <div className="col-foo  col-xl-3 col-sm-12 col-xs-12">
                            <Link to={'/blog/post?id=708'}><div className="block">
                                <img src={icon4} alt="" />
                                <h5>ВОДОКАНАЛИ УКРАЇНИ</h5>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-foo  col-xl-3 col-sm-6 col-xs-6">
                                <h4>КП "ЛУЦЬКВОДОКАНАЛ"</h4>
                                <Link to={"/"}><img src={main_logo} alt="" /></Link>
                            </div>
                            <div className="col-foo  col-xl-3 col-sm-6 col-xs-6">
                                <h4>Графік роботи</h4>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">понеділок - четвер: <b>7:45 - 17.00</b></li>
                                    <li className="list-group-item">п'ятниця: <b>8:00 - 16:00</b></li>
                                    <li className="list-group-item">перерва: <b>12:00 - 13:00</b></li>
                                    <li className="list-group-item">вихідні дні: <b>субота, неділя</b></li>
                                </ul>
                            </div>
                            <div className="col-foo col-xl-3 col-sm-6 col-xs-6">
                                <h4>Контакти</h4>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item"><b>(0332) 28-40-00</b> (приймальня)</li>
                                    <li className="list-group-item"><b>(0332) 28-40-10</b> (факс)</li>
                                    <li className="list-group-item"><b>info@vd.lutsk.ua</b></li>
                                </ul>
                            </div>
                            <div className="col-foo col-xl-3 col-sm-6 col-xs-6">
                                <h4>Адреса</h4>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">43010, м. Луцьк, вул. Дубнівська, 26</li>
                                </ul>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                        {renderFooter}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="text-center">
                    <h5>© {(new Date().getFullYear())} КП “ЛУЦЬКВОДОКАНАЛ”</h5>
                    <p>Всі права захищені</p>
                </div>
                <div className="d-flex justify-content-center">
                    <a href="https://www.facebook.com/%D0%9B%D1%83%D1%86%D1%8C%D0%BA%D0%B2%D0%BE%D0%B4%D0%BE%D0%BA%D0%B0%D0%BD%D0%B0%D0%BB-916481158399645/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a>
                    
                </div>
            </div>

        </div>);


}
export default FooterComponent;

