import React from "react";
import LazyLoad from 'react-lazyload';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../config"
import post_placeholder from "../../../../assets/images/post-placeholder.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import DateFormat from "../../../../components/dateFormat";
import "./index.scss"
/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const BlogItem = (props) => {
    return (<>
        <div className="card-blog" >
            <div className="card mt-4">
                <div className="row">
                    <div className="col-xl-4 col-sm-12">
                        <div className="img-square-wrapper">
                            <LazyLoad>
                                <img className="w-100" src={props.image == null ? post_placeholder : props.image} alt={props.name} />
                            </LazyLoad>
                        </div>
                    </div>
                    <div className="col-xl-8 col-sm-12">
                        <div className="card-body  h-100">
                            <h4 className="card-title">{props.name}</h4>
                            <p className="card-text" dangerouslySetInnerHTML={{ __html: (props.body.length < 270 ? props.body : props.body.substring(150, 0) + '...').replace(/<img[^>]*>/g,"") }} />
                            <div className="card-footer align-bottom">
                                <div className="row">
                                    <div className="col d-flex flex-row bd-highlight"><DateFormat date={props.date} /></div>
                                    <div className="col d-flex flex-row-reverse bd-highlight"><Link to={ROUTES.post + "?id=" + props.code}>Читати далі <FontAwesomeIcon icon={faArrowRight}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)

}

export default BlogItem;




