import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./blog.scss";
import { receiveHomePost } from "./actions/homePostActions"
import BlogItem from "../../modules/global/components/blogItem/blogItem"
import { Link } from "react-router-dom";
import { ROUTES } from "../../config";

/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const BlogComponent = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(receiveHomePost());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const postHomeData = useSelector(state => state.homePost.posts);
    const postRender = postHomeData.map(item => {
        return <BlogItem  key={Math.random()} image={item.image}
            name={item.name}
            body={item.body}
            code={item.code}
            date={item.date} />
    })
    return (<div>
        <div className="blog-container">
            <div className="container">
                <div className="news-header">Новини</div>
                {postRender}
            </div>
            <div className="d-flex justify-content-center btn-blog">
                <Link to={ROUTES.blog}><button type="button" className="btn">Переглянути все</button></Link>
            </div>
        </div>
    </div>)

}

export default BlogComponent;