import { getHomePostData  } from "../api"
import { toastError, toastWarn } from "../../../modules/global/errors.toast";
import {
    GET_HOMEPOST_REQUEST,
    GET_HOMEPOST_SUCCESS,
    GET_HOMEPOST_FAILURE,
} from "../const";


const request = () => ({ type: GET_HOMEPOST_REQUEST});

const success = (homePost) => ({
    type: GET_HOMEPOST_SUCCESS,
    payload: { homePost },
});
const failure = () => ({ type: GET_HOMEPOST_FAILURE});

export const receiveHomePost = () => async (dispatch) => {
    dispatch(request());
    try {
        const { data: receiveHomePostData } = await getHomePostData().catch((error)=> {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                }
            }})

        dispatch(success(receiveHomePostData));
    } catch (e) {
        dispatch(failure());
    };
};