/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import SocialMediaButtons from "../../modules/global/shareSocial"
import SearchItem from "../../modules/global/components/search/search";
import SeoComponent from "../../modules/global/shareMeta"
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import "./index.scss";



/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */
const data = [
    {
        name: '2010',
        uv: 23510,
        pv: 187 * 120,
    },
    {
        name: '2011',
        uv: 23319,
        pv: 131 * 120,
    },
    {
        name: '2012',
        uv: 23125,
        pv: 99 * 120,
    },
    {
        name: '2013',
        uv: 22325,
        pv: 87 * 120,
    },
    {
        name: '2014',
        uv: 20764,
        pv: 66 * 120,
    },
    {
        name: '2015',
        uv: 19673,
        pv: 46.7 * 120,
    },
    {
        name: '2016',
        uv: 19640,
        pv: 46 * 120,
    },
];

const ChartPost = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const url = window.location.href;

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <div>
        <SeoComponent />
        <div className="chart-post">
            <div className="container">

                <div className="row">
                    <div className="col-xl-8 col-sm-12">

                        <div className="card mt-4">
                            <SocialMediaButtons url={url} classname="share-buttons-page" />
                            <div className="row">
                                <div className="col-xl-12 col-sm-12">
                                    <div className="img-square-wrapper">
                                    </div>
                                </div>
                                <div className="col-xl-12 col-sm-12">
                                    <div className="card-body h-100">

                                        <div className="d-flex justify-content-between">

                                        </div>
                                        <h4 className="card-title">Енергоефективність </h4>

                                        <p className="card-text" />
                                        <div className="chart-post-data">
                                            <ResponsiveContainer width="100%" height="100%" >
                                                <ComposedChart
                                                    width={500}
                                                    height={400}
                                                    data={data}
                                                    scale={'4'}
                                                    margin={{
                                                        top: 50,
                                                        right: 20,
                                                        bottom: 40,
                                                        left: 20,
                                                    }}
                                                >
                                                    <CartesianGrid stroke="#f5f5f5" />
                                                    <XAxis dataKey="name" scale="band" />
                                                    <Tooltip radius={25}
                                                        formatter={(value, name) => {
                                                            if (name === "Витрати газу (тис. м3)") {
                                                                return value / 120
                                                            }
                                                            else {
                                                                return value
                                                            }
                                                        }}
                                                    />
                                                    <Legend radius={[10, 0, 0, 10]}/>
                                                    <Bar dataKey="uv" barSize={20} fill="#413ea0" name="Енергоспоживання (тис. кв)" />
                                                    <Line type="monotone" dataKey="pv" stroke="#ff7300" name="Витрати газу (тис. м3)" />
                                                </ComposedChart>
                                            </ResponsiveContainer>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-12 widget-column mt-0">
                        <SearchItem />
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default ChartPost;

