import {
    GET_BLOG_CATEGORY_REQUEST,
    GET_BLOG_CATEGORY_SUCCESS,
    GET_BLOG_CATEGORY_FAILURE,
} from "../const";



const initialState = {
    posts: [],
    total: 0
};
const getPosts = ({ state, postCategoryList, page}) => {
    return page !== 1 ? [...state.posts, ...postCategoryList.posts] : [...postCategoryList.posts];
    
}

const handlers = {
    [GET_BLOG_CATEGORY_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_BLOG_CATEGORY_SUCCESS]: (state, { payload: { postCategoryList, page } }) => ({
        ...state,
        loading: false,
        total: postCategoryList.total,
        posts: getPosts({state, postCategoryList, page}),
    }),
    [GET_BLOG_CATEGORY_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};
const categoryPostReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default categoryPostReducer;