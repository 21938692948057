import { toast } from 'react-toastify'

export const toastError = (error) => toast.error(error, {
    position: "bottom-right",
    autoClose: 5000,
    theme: "light",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: true
});

export const toastAlert = (error) => toast.info(error, {
    position: "bottom-right",
    autoClose: 5000,
    theme: "light",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: true
});

export const toastWarn = (error) => toast.warn(error, {
    position: "bottom-right",
    autoClose: 5000,
    theme: "light",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: true
});

export const toastSuccess = (error) => toast.success(error, {
    position: "bottom-right",
    autoClose: 5000,
    theme: "light",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: true
});