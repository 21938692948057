import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchItem from "../global/components/search/search";
import { receiveSchedulePage } from "./actions/SchedulePageActions"
import { receiveSinglePost } from "../../components/blogPost/actions/singlePostActions"
import SocialMediaButtons from "../global/shareSocial";
import post_placeholder from "../../assets/images/post-placeholder.png"
import "./index.scss"



/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const PopulationModule = () => {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const postId = urlParams.get('id');
    const url = window.location.href;
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(receiveSinglePost({ id: 845 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postId]);
    const singlePostData = useSelector(state => state.singlePost[0]);
    return (<>
        <div className="population-module">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-sm-12">
                        <div className="container">
                            <div className="row">
                                <div className="card w-100 h-100">
                                    <SocialMediaButtons url={url} classname="share-buttons-page" title={singlePostData.name !== null} image={singlePostData.image == null ? post_placeholder : singlePostData.image} alt={singlePostData.name} />
                               
                                    <div className="col-xl-12 col-sm-12">
                                  
                                        <div className="card-body  h-100">
                                        
                                            <h4 className="card-title">{singlePostData.name}</h4>
                                            <div className="d-flex justify-content-between">
                                                <div className="post-date"></div>
                                                
                                            </div>
                                            <p className="card-text" dangerouslySetInnerHTML={{ __html: singlePostData.body }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-12 widget-column">
                        <SearchItem />
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default PopulationModule;




