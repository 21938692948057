import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchItem from "../global/components/search/search";
import { receiveSchedulePage } from "./actions/SchedulePageActions"
import { receiveSinglePost } from "../../components/blogPost/actions/singlePostActions"
import { MDBDataTable } from 'mdbreact';
import Accordion from 'react-bootstrap/Accordion'
import SocialMediaButtons from "../global/shareSocial";
import post_placeholder from "../../assets/images/post-placeholder.png"
import "./index.scss"



/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const SchedulePageModule = () => {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const postId = urlParams.get('id');
    const url = window.location.href;
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(receiveSchedulePage({}));
        dispatch(receiveSinglePost({ id: 349 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postId]);
    const SchedulePageData = useSelector(state => state.SchedulePage.tables);
    const singlePostData = useSelector(state => state.singlePost[0]);
    return (<>
        <div className="SchedulePage-module">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-sm-12">
                        <div className="container">
                            <div className="row">
                            <SocialMediaButtons url={url} classname="share-buttons-page"/> 
                                <div className="card w-100 h-100">
                                    <div className="col-xl-12 col-sm-12">
                                        <div className="card-body  h-100">
                                            <h4 className="card-title">{singlePostData.name}</h4>
                                            <p className="card-text" dangerouslySetInnerHTML={{ __html: singlePostData.body }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-sm-12">
                                <Accordion defaultActiveKey={0} alwaysOpen>
                                            {SchedulePageData.map((item, index) => {
                                                return (<Accordion.Item eventKey={index}>
                                                    <Accordion.Header>{item.name}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <MDBDataTable
                                                            striped hover displayEntries={false} entries={5} pagesAmount={7}
                                                            data={item.body} className="w-auto" searchLabel="Пошук" infoLabel={["", "-", "з", ""]} paginationLabel={['Назад', 'Далі']}
                                                        />
                                                    </Accordion.Body>
                                                </Accordion.Item>)
                                            })}
                                        </Accordion>
                                        </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-12 widget-column">
                        <SearchItem />
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default SchedulePageModule;




