/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receivePosts } from "./actions/PostActions"
import "./index.scss";
import BlogItem from "../global/components/blogItem/blogItem";
import SearchItem from "../global/components/search/search";
/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */


const BlogPage = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(2);
  const { posts, total } = useSelector(state => state.posts);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(receivePosts({ page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNextPage = () => {
    dispatch(receivePosts({ page: page }));
    setPage(page + 1);
  };


  const blogRender = posts.map(item => {
    return <BlogItem key={Math.random()} image={item.image}
      name={item.name}
      body={item.body}
      code={item.code}
      date={item.date} />
  })

  return (<>
    <div className="blog-main">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-sm-12">
            {blogRender}
          </div>
          <div className="col-xl-4 col-sm-12">
            <SearchItem/>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center btn-blog">
        {posts.length < total && <button type="button" className="btn" onClick={getNextPage}>Показати ще</button>}
      </div>

    </div>
  </>)

}

export default BlogPage;



