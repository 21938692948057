import { getTablesData } from "../api"
import { toastError, toastWarn } from "../../global/errors.toast";
import {
    GET_TABLE_REQUEST,
    GET_TABLE_SUCCESS,
    GET_TABLE_FAILURE,
} from "../const";


const request = () => ({ type: GET_TABLE_REQUEST });

const success = (tables) => ({
    type: GET_TABLE_SUCCESS,
    payload: { tables },
});
const failure = () => ({ type: GET_TABLE_FAILURE });

export const receiveTables = (params) => async (dispatch) => {
    dispatch(request());
    try {
        getTablesData(params).then(({ data: receiveTableData }) => {
            dispatch(success(receiveTableData ));
        }).catch((error) => {
            if (error.response.status === 400) {
                toastWarn(`${error.response.status} Bad request`)
            } else {
                if (error.response.status === 500) {
                    toastError(`${error.response.status} Server error`)
                }
            }
        })
    } catch (e) {
        dispatch(failure());
    };
};

