/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./index.scss";
import main_logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";



/**
 * @desc Module "Home page".
 * @return {JSX.Element} - module that includes a default page, when user opens the web-app
 */

 
const NotFound = () => {
    return (<>
        <div className="container404">
            <div className="container text-center">
            <Link to={"/"}><img src={main_logo} alt="" /></Link>
                <div className="header">404</div>
                <p />
                Сторінку не знайдено
            </div>
        </div>

    </>)

}

export default NotFound;


