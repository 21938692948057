import {
    GET_SINGLEPUR_REQUEST,
    GET_SINGLEPUR_SUCCESS,
    GET_SINGLEPUR_FAILURE,
} from "../const";


const initialState = [
        {
            image: null,
            date: null,
            category:[],
            code: null
        },

    ]

const handlers = {
    [GET_SINGLEPUR_REQUEST]: () => ({ ...initialState, load: false}),
    [GET_SINGLEPUR_SUCCESS]: (state, { payload: { OnePost } }) => ({
        ...state,
        load: true,
        ...OnePost
        
    }),
    [GET_SINGLEPUR_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};

const singlePurchaseReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default singlePurchaseReducer;