/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import SeoComponent from "../global/shareMeta";

import "./index.scss";

/**
 * @desc Module "Privacy Policy page for GreenKa app".
 * @return {JSX.Element} - module that includes a privacy policy page when a user opens the page.
 */

const PolicyPost = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <SeoComponent />
      <div className="privacy-post">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-sm-12">
              <br/>
              <br/>
              <br/>
              <h1>Privacy Policy for GreenKa</h1>
              <p>Last updated: October 30, 2024</p>

              <p>
                This Privacy Policy describes Our policies and procedures on the
                collection, use, and disclosure of Your information when You use
                the GreenKa app. This policy complies with the privacy standards
                required by both the Apple App Store and Google Play Store.
              </p>

              <p>
                <strong>We collect limited information necessary for the proper functioning
                of the GreenKa app.</strong> This data includes diagnostic information and
                device identifiers to enhance app performance and user experience.
                By using the app, you agree to this policy.
              </p>

              <h2>What Data Do We Collect?</h2>
              <h3>1. Device ID</h3>
              <p>
                We collect the unique device identifier (ID) to link user-provided names
                with the device. This allows us to associate your in-app achievements,
                such as leaderboard standings, with your account.
              </p>

              <h3>2. Diagnostic Data</h3>
              <p>
                When using the GreenKa app, we collect <strong>diagnostic data</strong> to monitor
                the performance of the app. This data includes technical information
                such as:
              </p>
              <ul>
                <li>Crash reports</li>
                <li>Error logs (e.g., via Sentry service)</li>
                <li>App performance data</li>
                <li>Device and operating system information</li>
              </ul>

              <p>
                <strong>No personal information is collected</strong> during this process. The diagnostic data we collect is anonymized and cannot be used to identify any individual user.
              </p>

              <h2>Use of Collected Data</h2>
              <p>
                - <strong>Device ID</strong>: The device ID is used to manage your leaderboard scores and associate in-app activity with your profile.
              </p>
              <p>
                - <strong>Diagnostic Data</strong>: We use the diagnostic data solely to improve the app's performance and stability. The data helps us identify issues, fix bugs, and enhance the overall user experience. The data is anonymous and used for technical purposes only.
              </p>

              <h2>Security of Your Data</h2>
              <p>
                We implement appropriate technical and organizational measures to protect the diagnostic data and device ID we collect. While we strive to protect your data, please note that no internet transmission is entirely secure.
              </p>

              <h2>Children's Privacy</h2>
              <p>
                The GreenKa app is safe for all ages and does not collect personal data. We comply with the Children’s Online Privacy Protection Act (COPPA) and other similar regulations. The app does not knowingly collect personal data from children under the age of 13.
              </p>

              <h2>Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. Any changes will be posted on this page, and users will be notified in advance of any significant updates.
              </p>

              <h2>Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy or need further information, please contact us at:
              </p>
              <ul>
                <li>Email: info@vd.lutsk.ua</li>
              </ul>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyPost;
