import { combineReducers } from "redux";
import stationReducer from "../components/cabinet/reducers/stationReducer";
import homePostReducer from "../components/bloghome/reducers/homePostReducer";
import PostReducer from "../modules/blog/reducers/PostReducer";
import singlePostReducer from "../components/blogPost/reducers/singlePostReducer";
import categoriesReducer from "../modules/global/components/search/reducers/CategoryReducer";
import searchReducer from "../modules/global/components/search/reducers/SearchReducer";
import navReducer from "../modules/global/components/nav/reducers/NavReducer";
import alertsReducer from "../modules/global/components/nav/reducers/AlertsReducer";
import categoryPostReducer from "../components/categories/reducers/postCategoryListReducer"
import personalListReducer from "../modules/personal/reducers/PersonalReducer";
import singlePersonReducer from "../modules/personal/reducers/PersonReducer"
import tablesReducer from "../modules/tables/reducers/TableReducer"
import SchedulePageReducer from "../modules/SchedulePage/reducers/SchedulePageReducer"
import footernavReducer from "../modules/global/components/footer/reducers/FooterNavReducer"
import homePurchasesReducer from "../modules/purchases/reducers/PurchasesReducer";
import singlePurchaseReducer from "../components/purchasesPost/reducers/singlePurchaseReducer";
/**
 * @desc Reducer function that invokes every reducer inside the passed object.
 * @const
 * @type {object}
 */
const rootReducer = combineReducers({
    station: stationReducer,
    homePost: homePostReducer,
    posts: PostReducer,
    singlePost: singlePostReducer,
    categories: categoriesReducer,
    search: searchReducer,
    nav: navReducer,
    footer: footernavReducer,
    postCategoryList: categoryPostReducer,
    personList: personalListReducer,
    person: singlePersonReducer,
    tables: tablesReducer,
    SchedulePage: SchedulePageReducer,
    alerts: alertsReducer,
    purchases: homePurchasesReducer,
    purchase: singlePurchaseReducer

});

export default rootReducer;