import {
    GET_STATION_REQUEST,
    GET_STATION_SUCCESS,
    GET_STATION_FAILURE,
} from "../const";


const initialState = {
    data: {
        windDirection: '90',
        temperatureFeelLike:'20',
        temperature:'20',
        hiTemp:'20',
        hiTempDate: 1654595468000,
        loTemp: '18',
        loTempDate: 1654595468000,
        wind: '0',
        gust:'0',
        gustAt: 1654595468000,
        humidity: '0',
        rain:'0.0',
        seasonalRain:'0.0',
        barometerTrend: "Steady",
        barometer:'700.0',
        lastReceived: 1654595888000


        
    }
};

const handlers = {
    [GET_STATION_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_STATION_SUCCESS]: (state, { payload: { station } }) => ({
        ...state,
        loading: false,
        ...station,
    }),
    [GET_STATION_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
};


const stationReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};

export default stationReducer;