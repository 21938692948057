/**
 * @desc Routes for getting cards deck.
 * @const
 * @type {string}
 * @default
 */

/**
 * @desc Routes for the whole app.
 * @const
 * @type {object}
 * @default
 */
export const SERVER_URL = "https://api.vd.lutsk.ua"
//export const SERVER_URL = "http://localhost:8080"
export const MAIN_NAV_ID = 3
export const FOOTER_NAV_ID = 6
export const ENABLE_PRELOADER = true
export const shortSiteTitle = "КП «Луцькводоканал» | 90 років працюємо на благо міста"
export const siteDescription = "«Луцькводоканал» - постачаємо головне – чисту, якісну і безпечну воду з артезіанських свердловин. В режимі 24/7 забезпечуємо безперебійну роботу 570 кілометрів водопровідних мереж. Навіть за надскладних умов робимо усе можливе, аби подача води не припинялася ні на мить. Разом із усією Україною працюємо заради перемоги, миру і добробуту рідної держави."
export const siteTitle = "КП «Луцькводоканал» | 90 років працюємо на благо міста"
export const siteImage = "https://admin.vd.lutsk.ua/wp-content/uploads/2022/07/logo.png"
export const ROUTES = {
  root: "/",
  contact: "/contacts",
  purchases: "/purchases",
  purchase: "/purchase",
  blog: "/blog",
  post:"/blog/post",
  postCategories: "/blog/category",
  error: "/error",
  personList: "/leadership",
  person: "/leadership/person",
  table: "/tables",
  schedule: "/schedule",
  population: "/population",
  energy: "/energy",
  economy: "/economy"
};

