import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import main_logo from "../../../../../assets/images/logo.png"
import NotificationsComponent from "./notifications";
import { useEffect } from "react";
import { useHistory } from 'react-router-dom'
import $ from 'jquery'

const NavbarMain = (props) => {
    const history = useHistory()
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const siteMenuClone = () => {
        $(window).scroll(function() {
            if ($(document).scrollTop() > 32) {
                $('.sticky-wrapper').addClass('nav-fixed');
                $('.site-main-navbar').addClass('nav-margin');
            }
            else {
                $('.sticky-wrapper').removeClass('nav-fixed');
                $('.site-main-navbar').removeClass('nav-margin');
            }
        });
        $('body').on('click', '.site-mobile-menu-header svg', (e) => {
            var $this = $(this);
            e.preventDefault();

            if ($('body').hasClass('offcanvas-menu')) {
                $('body').removeClass('offcanvas-menu');
                $this.removeClass('active');
                document.body.style.overflow = "scroll";
                document.body.style.webkitOverflowScrolling = 'touch';
            } else {
                $('body').addClass('offcanvas-menu');
                document.body.style.overflow = "hidden";
                document.body.style.webkitOverflowScrolling = 'auto';
                $this.addClass('active');
            }
        })
        $('body').on('click', '.site-main-navbar .js-menu-toggle', (e) => {
            var $this = $(this);
            e.preventDefault();

            if ($('body').hasClass('offcanvas-menu')) {
                $('body').removeClass('offcanvas-menu');
                $this.removeClass('active');
                document.body.style.overflow = "scroll";
                document.body.style.webkitOverflowScrolling = 'touch';
            } else {
                $('body').addClass('offcanvas-menu');
                document.body.style.overflow = "hidden";
                document.body.style.webkitOverflowScrolling = 'auto';
                $this.addClass('active');
            }
        })
        setTimeout(function () {

            var counter = 0;
            $('.site-main-navbar .site-mobile-menu .has-children').each(function () {
                var $this = $(this);

                $this.find('.droped').attr({
                    'data-toggle': 'collapse',
                    'data-target': '#collapseItem' + counter,
                });

                $this.find('> ul').attr({
                    'class': 'collapse',
                    'id': 'collapseItem' + counter,
                });

                counter++;

            });

        }, 1000);

        $('body').on('click', '.site-main-navbar .site-mobile-menu .child-mobile', function (e) {
            var $this = $(this);
            if ($this.find('ul').hasClass('show')) {
                $this.find('.droped').removeClass('active');
                $this.find('ul').removeClass('show');
                document.body.style.overflow = "scroll";
                document.body.style.webkitOverflowScrolling = 'touch';
            } else {
                $this.find('.droped').addClass('active');
                $this.find('ul').addClass('show');
                document.body.style.overflow = "hidden";
                document.body.style.webkitOverflowScrolling = 'auto';
            }
            e.preventDefault();

        });

        $(window).resize(function () {
            var $this = $(this),
                w = $this.width();
            if (w > 1200) {
                if ($('body').hasClass('offcanvas-menu')) {
                    $('body').removeClass('offcanvas-menu');
                }
            }
        })
        // click outisde offcanvas
        $(document).mouseup((e) => {
            var container = $(".site-main-navbar .site-mobile-menu");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                if ($('body').hasClass('offcanvas-menu')) {
                    $('body').removeClass('offcanvas-menu');
                }
            }
        });
        if ($('body').hasClass('offcanvas-menu')) {
            $('body').removeClass('offcanvas-menu');
        }
    };


    const check_url = (url, name) => {
        if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
            return (<a href={url} className="nav-link" target="_blank" rel="noreferrer noopener" onClick={() => openInNewTab(url)}>{name}</a>)
        }
        else {
            return (<Link to={url} className="nav-link">{name}</Link>)
        }
    }

    const renderNav = props.navData.map(item => {
        if (item.sub.length) {
            return (<li key={Math.random()} className="has-children"><Link to={item.url} className="nav-link" onClick={(event) => event.preventDefault()}>{item.name} <FontAwesomeIcon icon={faAngleDown} /></Link><ul className="dropdown">
                {item.sub.map(item => {
                    return (<li key={Math.random()}>{check_url(item.url, item.name)}</li>
                    )
                })}
            </ul></li>)
        }
        else {
            return (<li key={Math.random()}>{check_url(item.url, item.name)}</li>)
        }
    })

    const renderMobileNav = props.navData.map(item => {
        if (item.sub.length) {
            return (<li key={Math.random()} className="has-children child-mobile"><Link to={item.url} className="nav-link droped" onClick={(event) => event.preventDefault()}>{item.name} <FontAwesomeIcon icon={faAngleDown} /></Link><ul className="collapse">
                {item.sub.map(item => {
                    return (<li key={Math.random()}>{check_url(item.url, item.name)}</li>
                    )
                })}
            </ul><hr /></li>)
        }
        else {
            return (<li key={Math.random()}>{check_url(item.url, item.name)}<hr /></li>)
        }
    })


    useEffect(() => {
        siteMenuClone()
        document.body.style.overflow = "scroll";
        document.body.style.webkitOverflowScrolling = 'touch';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.All]);

    useEffect(() => {
        return history.listen(() => {
            document.body.style.overflow = "scroll";
            document.body.style.webkitOverflowScrolling = 'touch';
            $('body').removeClass('offcanvas-menu');

        })
    }, [history])

    return (<>
        <div className="site-main-navbar">
            <div className="site-mobile-menu site-navbar-target">
                <div className="site-mobile-menu-body" >
                    <div className="site-mobile-menu-header">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="site-logo">
                                <Link to={'/'}><img src={main_logo} alt="Logo" /></Link>
                            </div>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>
                    <ul className="site-nav-wrap">
                        {renderMobileNav}
                    </ul>

                </div>
            </div>
            {props.children}
            <div id="sticky-wrapper" className="sticky-wrapper is-sticky">
                <header className="site-navbar js-sticky-header site-navbar-target" id="site-navbar" role="banner">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="site-logo">
                                <Link to={'/'}><img src={main_logo} alt="Logo" /></Link>
                            </div>
                            <nav className="site-navigation" role="navigation">
                                <ul className="site-menu main-menu js-clone-nav d-none d-lg-block">
                                    {renderNav}
                                    {/* <NotificationsComponent notifications={props.notifications} notificationsList={props.notificationsList} alertsData={props.alertsData}/> */}
                                </ul>
                            </nav>

                            <div className="toggle-button d-inline-block d-lg-none"><a href="/#"
                                className="site-menu-toggle py-5 js-menu-toggle text-black"><FontAwesomeIcon icon={faBars} /></a>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    </>);
}

export default NavbarMain;